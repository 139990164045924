import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Tooltip,
  TableSortLabel,
  Chip,
  Grid,
  Snackbar,
  Alert,
  Menu,
  MenuItem,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  Fade,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
  GetApp as ExportIcon,
  Publish as ImportIcon,
} from "@mui/icons-material";
import GenericForm from "./GenericForm";
import ImportDialog from "./ImportDialog";
import Papa from "papaparse";
import { format } from "date-fns"; // Add this import

const GenericEntityManager = ({
  entityName,
  entityNamePlural,
  fields,
  service,
  customActions = [],
  bulkActions = [],
  relationData = {},
  customValidations = {},
  getRowId = (row) => row.id,
  onError,
  onRowClick,

  // rowsPerPageOptions = [5, 10, 25, 50, 100],
}) => {
  const [entities, setEntities] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(fields[0].name);
  const [selected, setSelected] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentEntity, setCurrentEntity] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [optionMappings, setOptionMappings] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 25,
    pageCount: 0,
    total: 0,
  });

  useEffect(() => {
    fetchEntities();
  }, []);

  const fetchEntities = useCallback(async () => {
    try {
      const response = await service.getAll({
        page: pagination.page,
        pageSize: pagination.pageSize,
      });

      if (response && response.data && response.meta) {
        setEntities(response.data);
        setPagination(response.meta.pagination);
      } else {
        throw new Error("Unexpected response format from service.getAll");
      }
    } catch (error) {
      console.error(`Error fetching ${entityNamePlural}:`, error);
      if (onError) {
        onError(`Error fetching ${entityNamePlural}`);
      }
    }
  }, [
    service,
    pagination.page,
    pagination.pageSize,
    entityNamePlural,
    onError,
  ]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEdit = (entity) => {
    const editedEntity = { ...entity };
    fields.forEach((field) => {
      if (field.type === "datetime" && editedEntity[field.name]) {
        editedEntity[field.name] = new Date(editedEntity[field.name]);
      }
    });
    setCurrentEntity(editedEntity);
    setOpenDialog(true);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = entities.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handlePageChange = (event, newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 })); // Strapi uses 1-indexed pages
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 1, // Reset to first page when changing rows per page
    }));
  };

  const handleFilterChange = (fieldName, value) => {
    setFilterValues({ ...filterValues, [fieldName]: value });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  //   const handleEdit = (entity) => {
  //     setCurrentEntity(entity);
  //     setOpenDialog(true);
  //   };

  const handleDelete = async (id) => {
    try {
      await service.delete(id);
      fetchEntities();
      showSnackbar(`${entityName} deleted successfully`, "success");
    } catch (error) {
      console.error(`Error deleting ${entityName}:`, error);
      showSnackbar(`Error deleting ${entityName}`, "error");
    }
  };

  const formRef = useRef();

  const handleAddNew = () => {
    setCurrentEntity(null);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setCurrentEntity(null);
  };

  const handleSubmit = async (formData) => {
    try {
      const cleanedData = cleanData(formData);

      // Run custom validations
      for (const [field, validateFn] of Object.entries(customValidations)) {
        try {
          const error = await validateFn(cleanedData[field], cleanedData);
          if (error) {
            throw new Error(error);
          }
        } catch (validationError) {
          throw new Error(
            `Validation error for ${field}: ${validationError.message}`
          );
        }
      }

      console.log("Submitting data:", cleanedData);
      if (currentEntity) {
        const updatedEntity = await service.update(
          currentEntity.id,
          cleanedData
        );
        console.log("Entity updated:", updatedEntity);
        showSnackbar(`${entityName} updated successfully`, "success");
      } else {
        const newEntity = await service.create(cleanedData);
        console.log("Entity created:", newEntity);
        showSnackbar(`${entityName} created successfully`, "success");
      }
      fetchEntities();
      handleDialogClose();
    } catch (error) {
      console.error(`Error saving ${entityName}:`, error);
      showSnackbar(error.message, "error");
    }
  };

  const cleanData = (data) => {
    const cleanedData = {};
    for (const [key, value] of Object.entries(data)) {
      if (value !== null && value !== "") {
        const field = fields.find((f) => f.name === key);
        if (field && field.type === "number") {
          cleanedData[key] = Number(value);
        } else if (field && field.type === "date") {
          cleanedData[key] = new Date(value).toISOString().split("T")[0];
        } else if (field && field.type === "datetime") {
          cleanedData[key] = new Date(value).toISOString();
        } else if (field && field.type === "relation") {
          cleanedData[key] = value;
        } else if (field && field.type === "image" && value instanceof File) {
          cleanedData[key] = value;
        } else {
          cleanedData[key] = value;
        }
      }
    }
    return cleanedData;
  };

  const handleDialogSubmit = async () => {
    if (formRef.current) {
      try {
        await formRef.current.handleSubmit();
      } catch (error) {
        console.error("Error in form submission:", error);
        showSnackbar(error.message, "error");
      }
    } else {
      console.error("Form ref is not available");
    }
  };

  const fetchAllEntities = async () => {
    let allEntities = [];
    let currentPage = 1;
    let totalPages = 1;

    while (currentPage <= totalPages) {
      const response = await service.getAll({
        page: currentPage,
        pageSize: 100, // Adjust the page size as needed
      });

      if (response && response.data) {
        allEntities = [...allEntities, ...response.data];
        totalPages = response.meta.pagination.pageCount;
      }
      currentPage++;
    }

    return allEntities;
  };

  const handleExport = async () => {
    try {
      const entities = await fetchAllEntities();

      if (!Array.isArray(entities)) {
        throw new Error("Entities data is not an array");
      }

      const flattenObject = (obj, prefix = "") => {
        return Object.keys(obj).reduce((acc, k) => {
          const pre = prefix.length ? prefix + "." : "";
          if (
            typeof obj[k] === "object" &&
            obj[k] !== null &&
            !(obj[k] instanceof Date)
          ) {
            Object.assign(acc, flattenObject(obj[k], pre + k));
          } else {
            acc[pre + k] = obj[k];
          }
          return acc;
        }, {});
      };

      const csvData = entities.map((entity) => {
        const flatEntity = flattenObject(entity);
        const rowData = {};
        fields.forEach((field) => {
          if (field.type === "select" || field.type === "multiselect") {
            const options = optionMappings[field.name] || field.options;
            if (Array.isArray(flatEntity[field.name])) {
              rowData[field.name] = flatEntity[field.name]
                .map(
                  (value) =>
                    options.find((option) => option.value === value)?.label
                )
                .filter(Boolean)
                .join(", ");
            } else {
              rowData[field.name] =
                options.find(
                  (option) => option.value === flatEntity[field.name]
                )?.label || flatEntity[field.name];
            }
          } else if (field.type === "relation") {
            // Handle relation fields
            const relatedEntity =
              flatEntity[`${field.name}.data.attributes.name`] ||
              flatEntity[`${field.name}.data.id`];
            rowData[field.name] = relatedEntity || flatEntity[field.name];
          } else {
            rowData[field.name] = flatEntity[field.name];
          }
        });
        return rowData;
      });

      exportToCSV(csvData, `${entityNamePlural}_export.csv`);
    } catch (error) {
      console.error(`Error exporting ${entityNamePlural}:`, error);
      if (onError) {
        onError(`Error exporting ${entityNamePlural}`);
      }
    }
  };
  const exportToCSV = (data, filename) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(",")); // Add the header row

    data.forEach((row) => {
      const values = headers.map((header) => {
        const escapedValue = ("" + row[header]).replace(/"/g, '\\"'); // Escape double quotes
        return `"${escapedValue}"`; // Wrap each value in double quotes
      });
      csvRows.push(values.join(",")); // Add the data row
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", filename);
    link.click();
  };

  const handleMenuOpen = (event, entity) => {
    setAnchorEl(event.currentTarget);
    setSelectedEntity(entity);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedEntity(null);
  };

  const handleCustomAction = (action) => {
    action.handler(selectedEntity);
    handleMenuClose();
  };

  // const handleImport = async (file) => {
  //   return new Promise((resolve, reject) => {
  //     Papa.parse(file, {
  //       header: true,
  //       complete: async (results) => {
  //         try {
  //           for (let row of results.data) {
  //             const entityData = {};
  //             fields.forEach((field) => {
  //               if (field.type === "select" || field.type === "multiselect") {
  //                 const options = optionMappings[field.name] || field.options;
  //                 if (field.type === "multiselect") {
  //                   entityData[field.name] = row[field.name]
  //                     .split(",")
  //                     .map(
  //                       (value) =>
  //                         options.find(
  //                           (option) => option.label.trim() === value.trim()
  //                         )?.value
  //                     )
  //                     .filter(Boolean);
  //                 } else {
  //                   entityData[field.name] = options.find(
  //                     (option) => option.label === row[field.name]
  //                   )?.value;
  //                 }
  //               } else if (field.type === "number") {
  //                 entityData[field.name] = parseFloat(row[field.name]);
  //               } else {
  //                 entityData[field.name] = row[field.name];
  //               }
  //             });
  //             await service.create(entityData);
  //           }
  //           resolve(
  //             `Imported ${
  //               results.data.length
  //             } ${entityNamePlural.toLowerCase()} successfully`
  //           );
  //           fetchEntities(); // Refresh the data after import
  //         } catch (error) {
  //           reject(
  //             `Error importing ${entityNamePlural.toLowerCase()}: ${
  //               error.message
  //             }`
  //           );
  //         }
  //       },
  //       error: (error) => {
  //         reject(`Error parsing CSV: ${error.message}`);
  //       },
  //     });
  //   });
  // };

  const handleImportDialogOpen = () => {
    setImportDialogOpen(true);
  };

  const handleImportDialogClose = () => {
    setImportDialogOpen(false);
    fetchEntities(); // Refresh the data after import
  };

  const handleBulkAction = async (action) => {
    try {
      await action.handler(selected);
      fetchEntities();
      setSelected([]);
      showSnackbar(`Bulk action completed successfully`, "success");
    } catch (error) {
      console.error(`Error performing bulk action:`, error);
      showSnackbar(`Error performing bulk action`, "error");
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };
  const getRelationName = (fieldName, value) => {
    const field = fields.find((f) => f.name === fieldName);
    if (field && field.type === "relation") {
      const relatedEntity = relationData[field.relationTarget]?.find(
        (item) => item.id === value
      );
      return relatedEntity ? relatedEntity.name : "N/A";
    }
    return value;
  };

  const filteredEntities = useMemo(() => {
    return entities.filter((entity) => {
      // Apply filters
      for (const [key, value] of Object.entries(filterValues)) {
        if (value && fields.find((f) => f.name === key)?.filterable) {
          const field = fields.find((f) => f.name === key);
          const fieldValue =
            field.type === "relation"
              ? getRelationName(key, entity[key])
              : entity[key];

          if (typeof fieldValue === "string") {
            if (!fieldValue.toLowerCase().includes(value.toLowerCase())) {
              return false;
            }
          } else if (fieldValue instanceof Date) {
            if (fieldValue.toDateString() !== new Date(value).toDateString()) {
              return false;
            }
          } else if (typeof fieldValue === "number") {
            if (fieldValue !== Number(value)) {
              return false;
            }
          }
        }
      }

      // Apply search
      if (searchTerm) {
        const searchFields = fields.filter((field) => field.searchable);
        return searchFields.some((field) => {
          const fieldValue =
            field.type === "relation"
              ? getRelationName(field.name, entity[field.name])
              : entity[field.name];

          if (typeof fieldValue === "string") {
            return fieldValue.toLowerCase().includes(searchTerm.toLowerCase());
          } else if (fieldValue instanceof Date) {
            return fieldValue
              .toDateString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          } else if (typeof fieldValue === "number") {
            return fieldValue.toString().includes(searchTerm);
          }
          return false;
        });
      }
      return true;
    });
  }, [entities, filterValues, searchTerm, fields, relationData]);

  const renderCellContent = (entity, field) => {
    if (field.renderCell) {
      return field.renderCell(entity);
    }

    const value = entity[field.name];

    if (field.type === "image") {
      return (
        <Avatar
          src={entity[field.name]}
          alt={entity.name || "Image"}
          sx={{ width: 50, height: 50, cursor: "pointer" }}
          onClick={() => field.renderCell && field.renderCell(entity)}
        />
      );
    } else if (field.type === "relation") {
      return getRelationName(field.name, entity[field.name]);
    } else if (field.type === "select") {
      const option = field.options.find(
        (opt) => opt.value === entity[field.name]
      );
      return option ? option.label : entity[field.name];
    } else if (field.type === "date") {
      return value instanceof Date ? format(value, "yyyy-MM-dd") : value;
    } else if (field.type === "datetime") {
      return value instanceof Date
        ? format(value, "yyyy-MM-dd HH:mm:ss")
        : value;
    } else if (field.type === "boolean") {
      return value ? "Yes" : "No";
    } else {
      return entity[field.name];
    }
  };

  const renderFilterField = (field) => {
    if (field.type === "relation") {
      return (
        <FormControl fullWidth size="small">
          <InputLabel>{`Filter by ${field.label}`}</InputLabel>
          <Select
            value={filterValues[field.name] || ""}
            onChange={(e) => handleFilterChange(field.name, e.target.value)}
            label={`Filter by ${field.label}`}
          >
            <MenuItem value="">All</MenuItem>
            {relationData[field.relationTarget]?.map((option) => (
              <MenuItem key={option.id} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <TextField
          fullWidth
          label={`Filter by ${field.label}`}
          variant="outlined"
          size="small"
          value={filterValues[field.name] || ""}
          onChange={(e) => handleFilterChange(field.name, e.target.value)}
        />
      );
    }
  };

  const sortedEntities = useMemo(() => {
    const comparator = (a, b) => {
      if (b[orderBy] < a[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      return 0;
    };
    return [...filteredEntities].sort(comparator);
  }, [filteredEntities, order, orderBy]);

  return (
    <Fade in={true} timeout={800}>
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 600, color: "primary.main" }}
        >
          {entityNamePlural}
        </Typography>

        {/* Toolbar */}
        <Paper sx={{ mb: 2, p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <SearchIcon color="action" />,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                display="flex"
                justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                flexWrap="wrap"
                gap={1}
              >
                {customActions.length > 0 ? (
                  customActions.map((action, index) => (
                    <Button
                      key={index}
                      startIcon={action.icon && <action.icon />}
                      variant="contained"
                      color="secondary"
                      onClick={action.handler}
                      size="small"
                    >
                      {action.name}
                    </Button>
                  ))
                ) : (
                  <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="secondary"
                    onClick={handleAddNew}
                    size="small"
                  >
                    Add {entityName}
                  </Button>
                )}
                <Button
                  startIcon={<ExportIcon />}
                  variant="outlined"
                  onClick={handleExport}
                  size="small"
                >
                  Export
                </Button>
                <Button
                  startIcon={<ImportIcon />}
                  variant="outlined"
                  onClick={handleImportDialogOpen}
                  size="small"
                >
                  Import
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {/* Filters */}
        <Paper sx={{ mb: 2, p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Filters
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            {fields
              .filter((field) => field.filterable)
              .map((field) => (
                <Box
                  key={field.name}
                  sx={{ flexGrow: 1, minWidth: { xs: "100%", sm: "200px" } }}
                >
                  {renderFilterField(field)}
                </Box>
              ))}
          </Box>
        </Paper>

        {/* Table */}
        <TableContainer component={Paper} sx={{ mb: 2, overflow: "auto" }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "primary.light" }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < entities.length
                    }
                    checked={
                      entities.length > 0 && selected.length === entities.length
                    }
                    onChange={handleSelectAllClick}
                    sx={{ color: "common.white" }}
                  />
                </TableCell>
                {fields.map((field) => (
                  <TableCell
                    key={field.name}
                    sx={{ color: "common.white", fontWeight: 600 }}
                  >
                    <TableSortLabel
                      active={orderBy === field.name}
                      direction={orderBy === field.name ? order : "asc"}
                      onClick={() => handleRequestSort(field.name)}
                      sx={{
                        "&.MuiTableSortLabel-root": {
                          color: "common.white",
                        },
                        "&.MuiTableSortLabel-root:hover": {
                          color: "common.white",
                        },
                        "&.Mui-active": {
                          color: "common.white",
                        },
                        "& .MuiTableSortLabel-icon": {
                          color: "common.white !important",
                        },
                      }}
                    >
                      {field.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell sx={{ color: "common.white", fontWeight: 600 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedEntities
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((entity) => (
                  <TableRow key={entity.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selected.indexOf(entity.id) !== -1}
                        onChange={(event) => handleClick(event, entity.id)}
                      />
                    </TableCell>
                    {fields.map((field) => (
                      <TableCell key={field.name}>
                        {renderCellContent(entity, field)}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleEdit(entity)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton onClick={() => handleDelete(entity.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      {/* {customActions.length > 0 && (
                      <Tooltip title="More Actions">
                        <IconButton
                          onClick={(event) => handleMenuOpen(event, entity)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                    )} */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Pagination */}
        <TablePagination
          component="div"
          count={pagination.total}
          page={pagination.page - 1}
          onPageChange={handlePageChange}
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Rows:"
          sx={{
            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
              {
                display: { xs: "none", sm: "block" },
              },
          }}
        />

        {/* Bulk Actions */}
        {selected.length > 0 && (
          <Box sx={{ mt: 2 }}>
            {bulkActions.map((action) => (
              <Button
                key={action.name}
                onClick={() => handleBulkAction(action)}
                variant="contained"
                sx={{ mr: 1 }}
              >
                {action.name}
              </Button>
            ))}
          </Box>
        )}

        {/* Dialog for Add/Edit */}
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {currentEntity ? `Edit ${entityName}` : `Add New ${entityName}`}
          </DialogTitle>
          <DialogContent>
            <GenericForm
              ref={formRef}
              fields={fields}
              initialData={currentEntity}
              onSubmit={handleSubmit}
              relationData={relationData} // Ensure this prop is passed
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button
              onClick={handleDialogSubmit}
              color="primary"
              variant="contained"
            >
              {currentEntity ? "Update" : "Create"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Import Dialog */}
        <ImportDialog
          open={importDialogOpen}
          onClose={handleImportDialogClose}
          fields={fields}
          service={service}
        />

        {/* Image Preview Dialog */}
        <Dialog
          open={openImageDialog}
          onClose={handleCloseImageDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Image Preview</DialogTitle>
          <DialogContent>
            <img
              src={selectedImage}
              alt="Preview"
              style={{ width: "100%", height: "auto" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageDialog}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Fade>
  );
};

export default GenericEntityManager;
