import api from "../../config/api";

export const OrdersService = {
  getOrders: async ({ page, pageSize, orderNumber, customer, status, sortField, sortOrder }) => {
    let query = `/api/orders?populate[customer][fields][0]=firstName&populate[customer][fields][1]=lastName&populate[currency][fields][0]=symbol&populate[paymentMethod][fields][0]=name&populate[orderItems][populate][product][fields][0]=name&populate[orderItems][populate][product][fields][1]=price&populate[orderItems][populate][service][fields][0]=name&populate[orderItems][populate][service][fields][1]=price`;

    query += `&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;

    if (orderNumber) {
      query += `&filters[orderNumber][$contains]=${orderNumber}`;
    }
    if (customer) {
      query += `&filters[customer][id][$eq]=${customer}`;
    }
    if (status) {
      query += `&filters[status][$eq]=${status}`;
    }
    if (sortField) {
      query += `&sort[0]=${sortField}:${sortOrder}`;
    }

    const response = await api.get(query);
    return response.data;
  },


  getCustomers: async () => {
    const response = await api.get("/api/customers");
    return response.data.data;
  },

  getProducts: async (page = 1, pageSize = 25, search = '') => {
    let query = `/api/products?pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
    if (search) {
      query += `&filters[$or][0][name][$containsi]=${search}&filters[$or][1][code][$containsi]=${search}`;
    }
    const response = await api.get(query);
    return response.data;
  },

  getServices: async (page = 1, pageSize = 25, search = '') => {
    let query = `/api/services?pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
    if (search) {
      query += `&filters[name][$containsi]=${search}`;
    }
    const response = await api.get(query);
    return response.data;
  },


  getPaymentMethods: async () => {
    const response = await api.get("/api/payment-methods");
    return response.data.data;
  },

  getCurrencies: async () => {
    const response = await api.get("/api/currencies");
    return response.data.data;
  },

  getTaxes: async () => {
    const response = await api.get("/api/taxes");
    return response.data.data;
  },
  
  calculateTax: (subtotal, taxes) => {
    let totalTax = 0;
    taxes.forEach(tax => {
      if (tax.attributes.isActive) {
        if (tax.attributes.type === 'percentage') {
          totalTax += subtotal * (tax.attributes.rate / 100);
        } else if (tax.attributes.type === 'fixed') {
          totalTax += tax.attributes.rate;
        }
      }
    });
    return totalTax;
  },

  createOrder: async (orderData) => {
    const orderNumber = `ORD-${Date.now()}`;
    const orderPayload = {
      orderNumber,
      customer: orderData.customer,
      subtotal: orderData.subtotal,
      tax: orderData.tax,
      totalAmount: orderData.totalAmount,
      status: orderData.status,
      orderDate: orderData.orderDate,
      paymentMethod: orderData.paymentMethod,
      currency: orderData.currency,
    };

    const orderResponse = await api.post("/api/orders", { data: orderPayload });
    const createdOrder = orderResponse.data.data;

    for (const item of orderData.orderItems) {
      const orderItemData = {
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        order: createdOrder.id,
        ...(item.type === "product"
          ? { product: item.id }
          : { service: item.id }),
      };
      await api.post("/api/order-items", { data: orderItemData });
    }

    return createdOrder;
  },

  updateOrder: async (id, orderData) => {
    const orderPayload = {
      customer: orderData.customer,
      subtotal: orderData.subtotal,
      tax: orderData.tax,
      totalAmount: orderData.totalAmount,
      status: orderData.status,
      orderDate: orderData.orderDate,
      paymentMethod: orderData.paymentMethod,
      currency: orderData.currency,
    };

    await api.put(`/api/orders/${id}`, { data: orderPayload });

    // Delete existing order items
    const existingOrderItems = await api.get(`/api/order-items?filters[order][id][$eq]=${id}`);
    for (const item of existingOrderItems.data.data) {
      await api.delete(`/api/order-items/${item.id}`);
    }

    // Create new order items
    for (const item of orderData.orderItems) {
      const itemData = {
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        order: id,
        ...(item.type === "product"
          ? { product: item.id }
          : { service: item.id }),
      };
      await api.post("/api/order-items", { data: itemData });
    }

    return orderData;
  },

  deleteOrder: async (id) => {
    await api.delete(`/api/orders/${id}`);
  },
};