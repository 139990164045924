import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Typography, Snackbar, Alert, CircularProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseISO, format, isValid } from 'date-fns';
import Layout from '../components/Layout';
import api from '../config/api';
import GenericEntityManager from '../components/GenericEntityManager';
import Papa from 'papaparse';

const Employees = () => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [relationData, setRelationData] = useState({
    roles: [],
    branches: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fetchRoles = useCallback(async () => {
    try {
      const response = await api.get('/api/users-permissions/roles');
      return response.data.roles.map(role => ({ id: role.id, name: role.name }));
    } catch (error) {
      console.error('Error fetching roles:', error);
      return [
        { id: 'employee', name: 'Employee' },
        { id: 'manager', name: 'Manager' },
        { id: 'admin', name: 'Admin' }
      ];
    }
  }, []);

  const fetchBranches = useCallback(async () => {
    try {
      const response = await api.get('/api/branches');
      return response.data.data.map(branch => ({ id: branch.id, name: branch.attributes.name }));
    } catch (error) {
      console.error('Error fetching branches:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [roles, branches] = await Promise.all([fetchRoles(), fetchBranches()]);
        setRelationData({ roles, branches });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load necessary data. Please try again later.');
        setLoading(false);
      }
    };
    fetchData();
  }, [fetchRoles, fetchBranches]);

  const employeeFields = useMemo(() => [
    { name: 'username', label: 'Username', type: 'text', required: true, searchable: true, filterable: true },
    { name: 'email', label: 'Email', type: 'email', required: true, searchable: true, filterable: true },
    { name: 'password', label: 'Password', type: 'password', required: true, hideOnEdit: true },
    { name: 'firstName', label: 'First Name', type: 'text', required: true, searchable: true },
    { name: 'lastName', label: 'Last Name', type: 'text', required: true, searchable: true },
    { 
      name: 'role', 
      label: 'Role', 
      type: 'relation', 
      relationTarget: 'roles',
      required: true, 
      filterable: true
    },
    { 
      name: 'branch', 
      label: 'Branch', 
      type: 'relation', 
      relationTarget: 'branches',
      required: true, 
      filterable: true
    },
  ], []);

  const employeeService = useMemo(() => ({
    getAll: async (params = {}) => {
      try {
        let url = '/api/users?populate=*';
        if (params.page && params.pageSize) {
          url += `&pagination[page]=${params.page}&pagination[pageSize]=${params.pageSize}`;
        } else {
          url += '&pagination[pageSize]=-1'; // Fetch all customers for export
        }
        const response = await api.get(url);
        console.log('API Response:', response); // Debugging line
    
        // Since the response is directly an array, use it directly
        if (Array.isArray(response.data)) {
          const formattedData = response.data.map(employee => ({
            id: employee.id,
            ...employee,
            role: employee.role?.id,
            branch: employee.branch?.id
          }));
    
          // Assuming you might need a meta field, we mock it here
          return {
            data: formattedData,
            meta: { pagination: { total: formattedData.length } }, // Mocking pagination meta
          };
        } else {
          throw new Error("Unexpected response format from service.getAll");
        }
      } catch (error) {
        console.error('Error in getAll function:', error.message, error.stack);
        throw new Error('Error fetching employees');
      }
    },
    
    // getAll: async () => {
    //   try {
    //     const response = await api.get('/api/users?populate=*');
    //     return response.data.map(employee => ({
    //       id: employee.id,
    //       ...employee,
    //       role: employee.role?.id,
    //       branch: employee.branch?.id
    //     }));
    //   } catch (error) {
    //     console.error('Error fetching employees:', error);
    //     showSnackbar('Error fetching employees', 'error');
    //     throw error;
    //   }
    // },
    create: async (data) => {
      try {
        const response = await api.post('/api/users', data);
        showSnackbar('Employee created successfully', 'success');
        return response.data;
      } catch (error) {
        console.error('Error creating employee:', error.response?.data || error.message);
        showSnackbar(`Error creating employee: ${error.response?.data?.error?.message || error.message}`, 'error');
        throw error;
      }
    },
    update: async (id, data) => {
      try {
        if (!data.password) {
          delete data.password;
        }
        const response = await api.put(`/api/users/${id}`, data);
        showSnackbar('Employee updated successfully', 'success');
        return response.data;
      } catch (error) {
        console.error('Error updating employee:', error.response?.data || error.message);
        showSnackbar(`Error updating employee: ${error.response?.data?.error?.message || error.message}`, 'error');
        throw error;
      }
    },
    delete: async (id) => {
      try {
        await api.delete(`/api/users/${id}`);
        showSnackbar('Employee deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting employee:', error);
        showSnackbar(`Error deleting employee: ${error.message}`, 'error');
        throw error;
      }
    },
    export: async () => {
      try {
        const users = await employeeService.getAll();
        const csvData = users.map(user => ({
          username: user.username,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          role: relationData.roles.find(r => r.id === user.role)?.name || user.role,
          branch: relationData.branches.find(b => b.id === user.branch)?.name || user.branch
        }));
        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'employees.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error('Error exporting employees:', error);
        showSnackbar('Error exporting employees', 'error');
      }
    },
    import: async (file) => {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          complete: async (results) => {
            try {
              for (let row of results.data) {
                const roleId = relationData.roles.find(r => r.name === row.role)?.id;
                const branchId = relationData.branches.find(b => b.name === row.branch)?.id;
                await employeeService.create({
                  username: row.username,
                  email: row.email,
                  firstName: row.firstName,
                  lastName: row.lastName,
                  role: roleId,
                  branch: branchId,
                  password: 'defaultPassword'
                });
              }
              showSnackbar(`Imported ${results.data.length} employees successfully`, 'success');
              resolve();
            } catch (error) {
              showSnackbar(`Error importing employees: ${error.message}`, 'error');
              reject(error);
            }
          },
          error: (error) => {
            showSnackbar(`Error parsing CSV: ${error.message}`, 'error');
            reject(error);
          }
        });
      });
    }
  }), [relationData]);

  const customActions = useMemo(() => [
    { 
      name: 'Reset Password', 
      handler: async (employee) => {
        try {
          // Implement password reset logic here
          console.log('Reset password for:', employee);
          showSnackbar('Password reset successfully', 'success');
        } catch (error) {
          console.error('Error resetting password:', error);
          showSnackbar('Error resetting password', 'error');
        }
      }
    }
  ], []);

  const bulkActions = useMemo(() => [
    {
      name: 'Deactivate Selected',
      handler: async (selectedIds) => {
        try {
          // Implement deactivation logic here
          console.log('Deactivate employees:', selectedIds);
          showSnackbar('Employees deactivated successfully', 'success');
        } catch (error) {
          console.error('Error deactivating employees:', error);
          showSnackbar('Error deactivating employees', 'error');
        }
      }
    }
  ], []);

  if (loading) {
    return (
      <Layout>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Alert severity="error">{error}</Alert>
      </Layout>
    );
  }

  return (
    <Layout>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ width: '100%', mb: 4 }}>
          <GenericEntityManager
            entityName="Employee"
            entityNamePlural="Employees"
            fields={employeeFields}
            service={employeeService}
            // customActions={customActions}
            bulkActions={bulkActions}
            relationData={relationData}
          />
        </Box>
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </LocalizationProvider>
    </Layout>
  );
};

export default Employees;