import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  Box,
  Snackbar,
  Alert
} from '@mui/material';
import Layout from '../Layout';
import api from '../../config/api';

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [editing, setEditing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchProduct();
  }, [id]);

  const fetchProduct = async () => {
    try {
      const response = await api.get(`/api/products/${id}?populate=*`);
      setProduct(response.data.data);
    } catch (error) {
      console.error('Error fetching product:', error);
      setSnackbar({ open: true, message: 'Error fetching product details', severity: 'error' });
    }
  };

  const handleInputChange = (e) => {
    setProduct({
      ...product,
      attributes: {
        ...product.attributes,
        [e.target.name]: e.target.value
      }
    });
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
    fetchProduct(); // Refetch to discard changes
  };

  const handleSave = async () => {
    try {
      await api.put(`/api/products/${id}`, { data: product.attributes });
      setEditing(false);
      setSnackbar({ open: true, message: 'Product updated successfully', severity: 'success' });
      fetchProduct(); // Refetch to get the latest data
    } catch (error) {
      console.error('Error updating product:', error);
      setSnackbar({ open: true, message: 'Error updating product', severity: 'error' });
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await api.delete(`/api/products/${id}`);
        setSnackbar({ open: true, message: 'Product deleted successfully', severity: 'success' });
        navigate('/products'); // Redirect to products list
      } catch (error) {
        console.error('Error deleting product:', error);
        setSnackbar({ open: true, message: 'Error deleting product', severity: 'error' });
      }
    }
  };

  if (!product) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Layout>
      <Paper elevation={3}>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Product Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={product.attributes.name}
                onChange={handleInputChange}
                disabled={!editing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Price"
                name="price"
                type="number"
                value={product.attributes.price}
                onChange={handleInputChange}
                disabled={!editing}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                multiline
                rows={4}
                value={product.attributes.description}
                onChange={handleInputChange}
                disabled={!editing}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Category"
                name="category"
                value={product.attributes.category}
                onChange={handleInputChange}
                disabled={!editing}
              />
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                {editing ? (
                  <>
                    <Button variant="contained" color="primary" onClick={handleSave} sx={{ mr: 1 }}>
                      Save
                    </Button>
                    <Button variant="outlined" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button variant="contained" color="primary" onClick={handleEdit} sx={{ mr: 1 }}>
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={handleDelete}>
                      Delete
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default ProductDetails;