import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Paper,
  Grid,
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  Avatar,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  Person as PersonIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Event as EventIcon,
  ShoppingCart as ShoppingCartIcon,
  Note as NoteIcon,
  Visibility as VisibilityIcon 
} from '@mui/icons-material';
import Layout from '../Layout';
import api from '../../config/api';

const CustomerProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [appointments, setAppointments] = useState([]);
  const [orders, setOrders] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchCustomerData();
  }, [id]);

  const fetchCustomerData = async () => {
    setLoading(true);
    try {
      const [customerResponse, appointmentsResponse, ordersResponse, notesResponse] = await Promise.all([
        api.get(`/api/customers/${id}?populate=*`),
        api.get(`/api/appointments?filters[customer][id][$eq]=${id}&populate=*`),
        api.get(`/api/orders?filters[customer][id][$eq]=${id}&populate=*`),
        api.get(`/api/notes?filters[customer][id][$eq]=${id}&sort=createdAt:desc`),
      ]);
      setCustomer(customerResponse.data.data);
      setAppointments(appointmentsResponse.data.data);
      setOrders(ordersResponse.data.data);
      setNotes(notesResponse.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching customer data:', error);
      setError('Failed to load customer data. Please try again.');
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEdit = () => {
    setEditData({ ...customer.attributes });
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditSave = async () => {
    try {
      await api.put(`/api/customers/${id}`, { data: editData });
      setCustomer({ ...customer, attributes: editData });
      setEditDialogOpen(false);
      showSnackbar('Customer updated successfully', 'success');
    } catch (error) {
      console.error('Error updating customer:', error);
      showSnackbar('Error updating customer', 'error');
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      try {
        await api.delete(`/api/customers/${id}`);
        navigate('/customers');
        showSnackbar('Customer deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting customer:', error);
        showSnackbar('Error deleting customer', 'error');
      }
    }
  };

  const handleAddNote = () => {
    setNoteDialogOpen(true);
  };

  const handleNoteClose = () => {
    setNoteDialogOpen(false);
    setNewNote('');
  };

  const handleNoteSave = async () => {
    try {
      const response = await api.post('/api/notes', {
        data: {
          content: newNote,
          customer: id,
        },
      });
      setNotes([response.data.data, ...notes]);
      setNoteDialogOpen(false);
      setNewNote('');
      showSnackbar('Note added successfully', 'success');
    } catch (error) {
      console.error('Error adding note:', error);
      showSnackbar('Error adding note', 'error');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Layout>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography color="error">{error}</Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            Customer Profile
          </Typography>
          <Box>
            <IconButton onClick={handleEdit} color="primary">
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDelete} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <PersonIcon sx={{ mr: 1 }} />
              <Typography>{customer.attributes.firstName} {customer.attributes.lastName}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <EmailIcon sx={{ mr: 1 }} />
              <Typography>{customer.attributes.email}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <PhoneIcon sx={{ mr: 1 }} />
              <Typography>{customer.attributes.phone}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography><strong>Address:</strong> {customer.attributes.address}</Typography>
            <Box mt={1}>
              {customer.attributes.tags && customer.attributes.tags.map((tag) => (
                <Chip key={tag} label={tag} sx={{ mr: 1, mb: 1 }} />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ width: '100%', mb: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Appointments" icon={<EventIcon />} />
          <Tab label="Orders" icon={<ShoppingCartIcon />} />
          <Tab label="Notes" icon={<NoteIcon />} />
        </Tabs>
      </Box>

      {tabValue === 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Service</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments.map((appointment) => (
                <TableRow key={appointment.id}>
                  <TableCell>{new Date(appointment.attributes.dateTime).toLocaleString()}</TableCell>
                  <TableCell>{appointment.attributes.service?.data?.attributes?.name || 'N/A'}</TableCell>
                  <TableCell>
                    <Chip 
                      label={appointment.attributes.status} 
                      color={appointment.attributes.status === 'completed' ? 'success' : 'default'}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => {}}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {tabValue === 1 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order Number</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.attributes.orderNumber}</TableCell>
                  <TableCell>{new Date(order.attributes.orderDate).toLocaleDateString()}</TableCell>
                  <TableCell>{order.attributes.totalAmount} {order.attributes.currency?.data?.attributes?.symbol}</TableCell>
                  <TableCell>
                    <Chip 
                      label={order.attributes.status} 
                      color={order.attributes.status === 'completed' ? 'success' : 'default'}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => {}}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {tabValue === 2 && (
        <Box>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button 
              variant="contained" 
              startIcon={<AddIcon />} 
              onClick={handleAddNote}
            >
              Add Note
            </Button>
          </Box>
          <Grid container spacing={2}>
            {notes.map((note) => (
              <Grid item xs={12} key={note.id}>
                <Card>
                  <CardContent>
                    <Typography variant="body2">{note.attributes.content}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {new Date(note.attributes.createdAt).toLocaleString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Edit Customer Dialog */}
      <Dialog open={editDialogOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Customer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="firstName"
            label="First Name"
            type="text"
            fullWidth
            value={editData.firstName || ''}
            onChange={(e) => setEditData({ ...editData, firstName: e.target.value })}
          />
          <TextField
            margin="dense"
            name="lastName"
            label="Last Name"
            type="text"
            fullWidth
            value={editData.lastName || ''}
            onChange={(e) => setEditData({ ...editData, lastName: e.target.value })}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={editData.email || ''}
            onChange={(e) => setEditData({ ...editData, email: e.target.value })}
          />
          <TextField
            margin="dense"
            name="phone"
            label="Phone"
            type="tel"
            fullWidth
            value={editData.phone || ''}
            onChange={(e) => setEditData({ ...editData, phone: e.target.value })}
          />
          <TextField
            margin="dense"
            name="address"
            label="Address"
            type="text"
            fullWidth
            value={editData.address || ''}
            onChange={(e) => setEditData({ ...editData, address: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      {/* Add Note Dialog */}
      <Dialog open={noteDialogOpen} onClose={handleNoteClose}>
        <DialogTitle>Add Note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="note"
            label="Note"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoteClose}>Cancel</Button>
          <Button onClick={handleNoteSave} color="primary">Add</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default CustomerProfile;