import React from 'react';
import { SvgIcon } from '@mui/material';

// Custom icon components
const DashboardIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
  </SvgIcon>
);

const ProductIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M21 16.5c0 .38-.21.71-.53.88l-7.9 4.44c-.16.12-.36.18-.57.18-.21 0-.41-.06-.57-.18l-7.9-4.44A.991.991 0 013 16.5v-9c0-.38.21-.71.53-.88l7.9-4.44c.16-.12.36-.18.57-.18.21 0 .41.06.57.18l7.9 4.44c.32.17.53.5.53.88v9zM12 4.15L6.04 7.5 12 10.85l5.96-3.35L12 4.15z" />
  </SvgIcon>
);

const CustomerIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
  </SvgIcon>
);

const OrderIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
  </SvgIcon>
);

const AppointmentIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z" />
  </SvgIcon>
);

const EmployeeIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
  </SvgIcon>
);

const InventoryIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M20 2H4c-1 0-2 .9-2 2v3.01c0 .72.43 1.34 1 1.69V20c0 1.1 1.1 2 2 2h14c.9 0 2-.9 2-2V8.7c.57-.35 1-.97 1-1.69V4c0-1.1-1-2-2-2zm-5 12H9v-2h6v2zm5-7H4V4l16-.02V7z" />
  </SvgIcon>
);

const ServiceIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M14.17 13.71l1.4-2.42c.09-.15.05-.34-.08-.45l-1.48-1.16c.03-.22.05-.45.05-.68s-.02-.46-.05-.69l1.48-1.16c.13-.11.17-.3.08-.45l-1.4-2.42c-.09-.15-.27-.21-.43-.15l-1.74.7c-.36-.28-.75-.51-1.18-.69l-.26-1.85c-.03-.16-.18-.29-.35-.29h-2.8c-.17 0-.32.13-.35.29l-.26 1.85c-.43.18-.82.41-1.18.69l-1.74-.7c-.16-.06-.34 0-.43.15l-1.4 2.42c-.09.15-.05.34.08.45l1.48 1.16c-.03.22-.05.45-.05.68s.02.46.05.69l-1.48 1.16c-.13.11-.17.3-.08.45l1.4 2.42c.09.15.27.21.43.15l1.74-.7c.36.28.75.51 1.18.69l.26 1.85c.03.16.18.29.35.29h2.8c.17 0 .32-.13.35-.29l.26-1.85c.43-.18.82-.41 1.18-.69l1.74.7c.16.06.34 0 .43-.15zM8.5 13c-1.93 0-3.5-1.57-3.5-3.5S6.57 6 8.5 6s3.5 1.57 3.5 3.5S10.43 13 8.5 13z" />
  </SvgIcon>
);

const BranchIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3h7zM7 9H4V5h3v4zm10 6h3v4h-3v-4zm0-10h3v4h-3V5z" />
  </SvgIcon>
);

const PaymentIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
  </SvgIcon>
);

const TaxIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z" />
  </SvgIcon>
);

const CurrencyIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
  </SvgIcon>
);

const SettingsIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z" />
  </SvgIcon>
);

const LogoutIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" />
  </SvgIcon>
);

export const menuItems = [
  { 
    text: "Dashboard", 
    icon: <DashboardIcon />, 
    path: "/",
    description: "Overview of your business"
  },
  { 
    text: "Products", 
    icon: <ProductIcon />, 
    path: "/products",
    description: "Manage your product catalog"
  },
  { 
    text: "Customers", 
    icon: <CustomerIcon />, 
    path: "/customers",
    description: "View and manage customer information"
  },
  { 
    text: "Orders", 
    icon: <OrderIcon />, 
    path: "/orders",
    description: "Track and process customer orders"
  },
  { 
    text: "Appointments", 
    icon: <AppointmentIcon />, 
    path: "/appointments",
    description: "Schedule and manage appointments"
  },
  { 
    text: "Employees", 
    icon: <EmployeeIcon />, 
    path: "/employees",
    description: "Manage your staff"
  },
  { 
    text: "Inventory", 
    icon: <InventoryIcon />, 
    path: "/inventory",
    description: "Track your stock levels"
  },
  { 
    text: "Services", 
    icon: <ServiceIcon />, 
    path: "/services",
    description: "Manage your service offerings"
  },
  { 
    text: "Branches", 
    icon: <BranchIcon />, 
    path: "/branches",
    description: "Manage multiple locations"
  },
  { 
    text: "Payment Methods", 
    icon: <PaymentIcon />, 
    path: "/payment-methods",
    description: "Set up and manage payment options"
  },
  { 
    text: "Taxes", 
    icon: <TaxIcon />, 
    path: "/taxes",
    description: "Configure tax settings"
  },
  { 
    text: "Currencies", 
    icon: <CurrencyIcon />, 
    path: "/currencies",
    description: "Manage supported currencies"
  },
  { 
    text: "Settings", 
    icon: <SettingsIcon />, 
    path: "/settings",
    description: "Configure system settings"
  },
  { 
    text: "Logout", 
    icon: <LogoutIcon />, 
    path: "/logout",
    description: "Sign out of your account"
  },
];