import React, { useState, useEffect, useCallback ,useRef} from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
  Snackbar,
  Alert,
  TablePagination,
  Grid,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Autocomplete,
  TableSortLabel,
  Skeleton,
} from "@mui/material";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { useOrdersController } from "./OrdersController";
import Layout from "../../components/Layout";
import { PhoneInput } from "../../components/PhoneInput";
import { debounce } from "lodash";
import Confetti from 'react-confetti'; // Import Confetti

const Orders = () => {
  const {
    orders,
    customers,
    products,
    services,
    paymentMethods,
    currencies,
    newOrder,
    editingOrder,
    snackbar,
    handleOpen,
    handleClose,
    handleInputChange,
    handleSubmit,
    updateOrderItem,
    addOrderItem,
    removeOrderItem,
    handleEditOpen,
    handleEditClose,
    handleEditInputChange,
    updateEditOrderItem,
    addEditOrderItem,
    removeEditOrderItem,
    handleEditSubmit,
    handleSnackbarClose,
    pagination,
    handlePageChange,
    handleRowsPerPageChange,
    filters,
    handleFilterChange,
    applyFilters,
    searchCustomer,
    createCustomer,
    productsPagination,
    servicesPagination,
    handleProductSearch,
    handleServiceSearch,
    loadMoreProducts,
    loadMoreServices,
    handleSort,
    sortField,
    sortOrder,
  } = useOrdersController();
  const [productInput, setProductInput] = useState("");
  const [serviceInput, setServiceInput] = useState("");
  // Add these new state variables
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("orderNumber");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showConfetti, setShowConfetti] = useState(false);
  const confettiRef = useRef(null);

  // Debounce search functions
  const debouncedProductSearch = useCallback(
    debounce((value) => handleProductSearch(value), 300),
    []
  );

  const debouncedServiceSearch = useCallback(
    debounce((value) => handleServiceSearch(value), 300),
    []
  );

  const handleProductInputChange = (event, value, reason) => {
    setProductInput(value);
    if (reason === "input") {
      debouncedProductSearch(value);
    }
  };

  const handleServiceInputChange = (event, value, reason) => {
    setServiceInput(value);
    if (reason === "input") {
      debouncedServiceSearch(value);
    }
  };
  const handleNewOrderSubmit = async (e) => {
    try {
      await handleSubmit(e); // Call the renamed function
      setShowConfetti(true); 
      setTimeout(() => setShowConfetti(false), 5000); 
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);
  const [customerSearchQuery, setCustomerSearchQuery] = useState("");
  const [newCustomer, setNewCustomer] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
  });
  const [isSearching, setIsSearching] = useState(false);

  const handleCustomerSearch = async () => {
    setIsSearching(true);
    const result = await searchCustomer(customerSearchQuery);
    setIsSearching(false);
    if (result) {
      setIsCustomerDialogOpen(false);
      handleOpen(result.id, `${result.firstName} ${result.lastName}`);
    } else {
      setNewCustomer((prev) => ({ ...prev, phone: customerSearchQuery }));
    }
  };

  const handleCreateCustomer = async () => {
    try {
      const createdCustomer = await createCustomer(newCustomer);
      setIsCustomerDialogOpen(false);
      handleOpen(
        createdCustomer.id,
        `${createdCustomer.firstName} ${createdCustomer.lastName}`
      );
    } catch (error) {
      console.error("Error creating customer:", error);
    }
  };

  const handleNewCustomerChange = (field, value) => {
    setNewCustomer({ ...newCustomer, [field]: value });
  };
  const SkeletonOption = () => (
    <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
      <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1 }} />
      <Skeleton variant="text" width={150} />
    </Box>
  );

  const renderProductServiceSelection = (index, item) => (
    <Box sx={{ display: "flex", gap: 2, my: 2, alignItems: "center" }}>
      <Autocomplete
        fullWidth
        options={[
          ...products,
          ...services,
          ...(productInput.length > 0 && products.length === 0
            ? [{ loading: true, key: "product-loading" }]
            : []),
          ...(serviceInput.length > 0 && services.length === 0
            ? [{ loading: true, key: "service-loading" }]
            : []),
        ]}
        getOptionLabel={(option) =>
          option.attributes?.code
            ? `${option.attributes.code} - ${option.attributes.name}`
            : option.attributes?.name || ""
        }
        renderInput={(params) => (
          <TextField {...params} label="Product/Service" />
        )}
        value={
          item.id
            ? item.type === "product"
              ? products.find((p) => p.id === item.id)
              : services.find((s) => s.id === item.id)
            : null
        }
        onChange={(event, newValue) => {
          if (newValue) {
            const type = newValue.attributes.code ? "product" : "service";
            updateOrderItem(
              index,
              "productOrService",
              `${type}-${newValue.id}`
            );
          } else {
            updateOrderItem(index, "productOrService", "");
          }
        }}
        onInputChange={handleProductInputChange}
        filterOptions={(options, state) => options}
        loading={productInput.length > 0 && products.length === 0}
        loadingText="Loading..."
        renderOption={(props, option) => {
          if (option.loading) {
            return <SkeletonOption key={option.key} />;
          }
          return (
            <li {...props}>
              {option.attributes?.code
                ? `${option.attributes.code} - ${option.attributes.name}`
                : option.attributes?.name}
            </li>
          );
        }}
        ListboxProps={{
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              listboxNode.scrollTop + listboxNode.clientHeight >=
              listboxNode.scrollHeight - 100
            ) {
              loadMoreProducts();
              loadMoreServices();
            }
          },
        }}
        ListboxComponent={React.forwardRef((props, ref) => (
          <div ref={ref}>
            <ul {...props} />
            {(productInput.length > 0 &&
              products.length < productsPagination.total) ||
            (serviceInput.length > 0 &&
              services.length < servicesPagination.total) ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
                <CircularProgress size={20} />
              </Box>
            ) : null}
          </div>
        ))}
        noOptionsText={
          productInput.length > 0 || serviceInput.length > 0
            ? "No results found"
            : "Start typing to search"
        }
      />

      <TextField
        label="Quantity"
        type="number"
        value={item.quantity}
        onChange={(e) =>
          updateOrderItem(index, "quantity", parseInt(e.target.value) || 0)
        }
      />
      <TextField
        label="Unit Price"
        type="number"
        value={item.unitPrice}
        onChange={(e) =>
          updateOrderItem(index, "unitPrice", parseFloat(e.target.value) || 0)
        }
      />
      <IconButton onClick={() => removeOrderItem(index)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );

  // function getComparator(order, orderBy) {
  //   return order === "desc"
  //     ? (a, b) => descendingComparator(a, b, orderBy)
  //     : (a, b) => -descendingComparator(a, b, orderBy);
  // }

  // function stableSort(array, comparator) {
  //   const stabilizedThis = array.map((el, index) => [el, index]);
  //   stabilizedThis.sort((a, b) => {
  //     const order = comparator(a[0], b[0]);
  //     if (order !== 0) return order;
  //     return a[1] - b[1];
  //   });
  //   return stabilizedThis.map((el) => el[0]);
  // }

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4" component="h1">
          Orders
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsCustomerDialogOpen(true)}
        >
          Create New Order
        </Button>
      </Box>

      {/* Customer Search Dialog */}
      <Dialog
        open={isCustomerDialogOpen}
        onClose={() => setIsCustomerDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Find or Create Customer</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <PhoneInput
              value={customerSearchQuery}
              onChange={(value) => setCustomerSearchQuery(value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              onClick={handleCustomerSearch}
              disabled={isSearching || !customerSearchQuery}
              startIcon={
                isSearching ? <CircularProgress size={20} /> : <SearchIcon />
              }
              fullWidth
            >
              {isSearching ? "Searching..." : "Search"}
            </Button>
          </Box>
          <Typography variant="h6" gutterBottom>
            New Customer
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                value={newCustomer.firstName}
                onChange={(e) =>
                  handleNewCustomerChange("firstName", e.target.value)
                }
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={newCustomer.lastName}
                onChange={(e) =>
                  handleNewCustomerChange("lastName", e.target.value)
                }
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={newCustomer.email}
                onChange={(e) =>
                  handleNewCustomerChange("email", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                value={newCustomer.address}
                onChange={(e) =>
                  handleNewCustomerChange("address", e.target.value)
                }
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCustomerDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleCreateCustomer}
            color="primary"
            variant="contained"
          >
            Create Customer & Start Order
          </Button>
        </DialogActions>
      </Dialog>
      {showConfetti && <Confetti ref={confettiRef} />} {/* Render confetti */}

      {/* Filters */}
      <Paper sx={{ mb: 2, p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Order Number"
              name="orderNumber"
              value={filters.orderNumber}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              options={customers}
              getOptionLabel={(option) =>
                `${option.attributes.firstName} ${option.attributes.lastName}`
              }
              renderInput={(params) => (
                <TextField {...params} label="Customer" />
              )}
              value={
                filters.customer
                  ? customers.find((c) => c.id === filters.customer)
                  : null
              }
              onChange={(event, newValue) => {
                handleFilterChange({
                  target: {
                    name: "customer",
                    value: newValue ? newValue.id : "",
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="processing">Processing</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={applyFilters}
              fullWidth
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === "orderNumber"}
                  direction={sortField === "orderNumber" ? sortOrder : "asc"}
                  onClick={() => handleSort("orderNumber")}
                >
                  Order Number
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "customer.firstName"}
                  direction={
                    sortField === "customer.firstName" ? sortOrder : "asc"
                  }
                  onClick={() => handleSort("customer.firstName")}
                >
                  Customer
                </TableSortLabel>
              </TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "orderDate"}
                  direction={sortField === "orderDate" ? sortOrder : "asc"}
                  onClick={() => handleSort("orderDate")}
                >
                  Order Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order.attributes.orderNumber}</TableCell>
                <TableCell>
                  {order.attributes.customer?.data?.attributes?.firstName}{" "}
                  {order.attributes.customer?.data?.attributes?.lastName}
                </TableCell>
                <TableCell>
                  {order.attributes.totalAmount}{" "}
                  {order.attributes.currency?.data?.attributes?.symbol}
                </TableCell>
                <TableCell>{order.attributes.status}</TableCell>
                <TableCell>
                  {new Date(order.attributes.orderDate).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/orders/${order.id}`}
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1 }}
                  >
                    View Details
                  </Button>
                  <IconButton
                    onClick={() => handleEditOpen(order)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={pagination.total}
        page={pagination.page}
        onPageChange={handlePageChange}
        rowsPerPage={pagination.pageSize}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[5, 10, 25]}
      />

      {/* Create Order Dialog */}
      <Dialog
        open={newOrder !== null}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New Order</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel>Customer</InputLabel>
            <Select
              name="customer"
              value={newOrder?.customer || ""}
              onChange={handleInputChange}
              disabled
            >
              <MenuItem value={newOrder?.customer || ""}>
                {newOrder?.customerName || "No customer selected"}
              </MenuItem>
            </Select>
          </FormControl>

          {newOrder?.orderItems.map(
            (item, index) => renderProductServiceSelection(index, item)

            // <Box
            //   key={index}
            //   sx={{ display: "flex", gap: 2, my: 2, alignItems: "center" }}
            // >
            //   <FormControl fullWidth>
            //     <InputLabel>Product/Service</InputLabel>
            //     <renderProductsServices />
            //     {/* <Select
            //       value={item.type && item.id ? `${item.type}-${item.id}` : ""}
            //       onChange={(e) =>
            //         updateOrderItem(index, "productOrService", e.target.value)
            //       }
            //     >
            //       <MenuItem value="" disabled>
            //         Select a product or service
            //       </MenuItem>
            //       <MenuItem value="" disabled>
            //         Products
            //       </MenuItem>
            //       {products.map((product) => (
            //         <MenuItem key={product.id} value={`product-${product.id}`}>
            //           {product.attributes.name} - {product.attributes.price}{" "}
            //           {product.attributes.currency?.data?.attributes?.symbol}
            //         </MenuItem>
            //       ))}
            //       <MenuItem value="" disabled>
            //         Services
            //       </MenuItem>
            //       {services.map((service) => (
            //         <MenuItem key={service.id} value={`service-${service.id}`}>
            //           {service.attributes.name} - {service.attributes.price}{" "}
            //           {service.attributes.currency?.data?.attributes?.symbol}
            //         </MenuItem>
            //       ))}
            //     </Select> */}
            //   </FormControl>
            //   <TextField
            //     label="Quantity"
            //     type="number"
            //     value={item.quantity}
            //     onChange={(e) =>
            //       updateOrderItem(
            //         index,
            //         "quantity",
            //         parseInt(e.target.value) || 0
            //       )
            //     }
            //   />
            //   <TextField
            //     label="Unit Price"
            //     type="number"
            //     value={item.unitPrice}
            //     InputProps={{
            //       readOnly: true,
            //     }}
            //   />
            //   <IconButton onClick={() => removeOrderItem(index)}>
            //     <DeleteIcon />
            //   </IconButton>
            // </Box>
          )}
          <Button startIcon={<AddIcon />} onClick={addOrderItem}>
            Add Item
          </Button>
          <TextField
            margin="dense"
            name="subtotal"
            label="Subtotal"
            type="number"
            fullWidth
            value={newOrder?.subtotal || 0}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            margin="dense"
            name="tax"
            label="Tax (Automatically Calculated)"
            type="number"
            fullWidth
            value={newOrder?.tax || 0}
            InputProps={{ readOnly: true }}
          />
          <TextField
            margin="dense"
            name="totalAmount"
            label="Total Amount"
            type="number"
            fullWidth
            value={newOrder?.totalAmount || 0}
            InputProps={{
              readOnly: true,
            }}
          />

          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={newOrder?.status || ""}
              onChange={handleInputChange}
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="processing">Processing</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Payment Method</InputLabel>
            <Select
              name="paymentMethod"
              value={newOrder?.paymentMethod || ""}
              onChange={handleInputChange}
            >
              {paymentMethods.map((method) => (
                <MenuItem key={method.id} value={method.id}>
                  {method.attributes.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Currency</InputLabel>
            <Select
              name="currency"
              value={newOrder?.currency || ""}
              onChange={handleInputChange}
            >
              {currencies.map((currency) => (
                <MenuItem key={currency.id} value={currency.id}>
                  {currency.attributes.name} ({currency.attributes.symbol})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="orderDate"
            label="Order Date"
            type="date"
            fullWidth
            value={newOrder?.orderDate || ""}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleNewOrderSubmit} color="primary">
            Create Order
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Order Dialog */}
      <Dialog
        open={editingOrder !== null}
        onClose={handleEditClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Order</DialogTitle>
        <DialogContent>
          {editingOrder && (
            <>
              <FormControl fullWidth margin="dense">
                <InputLabel>Customer</InputLabel>
                <Select
                  name="customer"
                  value={editingOrder.customer || ""}
                  onChange={handleEditInputChange}
                >
                  {customers.map((customer) => (
                    <MenuItem key={customer.id} value={customer.id}>
                      {customer.attributes.firstName}{" "}
                      {customer.attributes.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {editingOrder.orderItems.map((item, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", gap: 2, my: 2, alignItems: "center" }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Product/Service</InputLabel>
                    <Select
                      value={item ? `${item.type}-${item.itemId}` : ""}
                      onChange={(e) =>
                        updateEditOrderItem(
                          index,
                          "productOrService",
                          e.target.value
                        )
                      }
                    >
                      <MenuItem value="" disabled>
                        Products
                      </MenuItem>
                      {products.map((product) => (
                        <MenuItem
                          key={product.id}
                          value={`product-${product.id}`}
                        >
                          {product.attributes?.name || "Unknown Product"} -{" "}
                          {product.attributes?.price || "N/A"}{" "}
                          {product.attributes?.currency?.data?.attributes
                            ?.symbol || ""}
                        </MenuItem>
                      ))}
                      <MenuItem value="" disabled>
                        Services
                      </MenuItem>
                      {services.map((service) => (
                        <MenuItem
                          key={service.id}
                          value={`service-${service.id}`}
                        >
                          {service.attributes?.name || "Unknown Service"} -{" "}
                          {service.attributes?.price || "N/A"}{" "}
                          {service.attributes?.currency?.data?.attributes
                            ?.symbol || ""}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Quantity"
                    type="number"
                    value={item?.quantity || ""}
                    onChange={(e) =>
                      updateEditOrderItem(
                        index,
                        "quantity",
                        parseInt(e.target.value) || 0
                      )
                    }
                  />
                  <TextField
                    label="Unit Price"
                    type="number"
                    value={item?.unitPrice || ""}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <IconButton onClick={() => removeEditOrderItem(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button startIcon={<AddIcon />} onClick={addEditOrderItem}>
                Add Item
              </Button>
              <TextField
                margin="dense"
                name="subtotal"
                label="Subtotal"
                type="number"
                fullWidth
                value={editingOrder?.attributes?.subtotal || 0}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                name="tax"
                label="Tax (Automatically Calculated)"
                type="number"
                fullWidth
                value={editingOrder?.attributes?.tax || 0}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                margin="dense"
                name="totalAmount"
                label="Total Amount"
                type="number"
                fullWidth
                value={editingOrder?.attributes?.totalAmount || 0}
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={editingOrder.attributes.status}
                  onChange={handleEditInputChange}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="processing">Processing</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel>Payment Method</InputLabel>
                <Select
                  name="paymentMethod"
                  value={editingOrder.paymentMethod}
                  onChange={handleEditInputChange}
                >
                  {paymentMethods.map((method) => (
                    <MenuItem key={method.id} value={method.id}>
                      {method.attributes.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel>Currency</InputLabel>
                <Select
                  name="currency"
                  value={editingOrder.currency}
                  onChange={handleEditInputChange}
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency.id} value={currency.id}>
                      {currency.attributes.name} ({currency.attributes.symbol})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                margin="dense"
                name="orderDate"
                label="Order Date"
                type="date"
                fullWidth
                value={editingOrder.attributes.orderDate.split("T")[0]}
                onChange={handleEditInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSubmit} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Orders;
