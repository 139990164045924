import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
  Grid,
  Autocomplete,
  Button
} from "@mui/material";
import Layout from "../components/Layout";
import GenericEntityManager from "../components/GenericEntityManager";
import api from "../config/api";
import {Visibility as VisibilityIcon} from '@mui/icons-material'; 

const countryCodes = [
  { code: "+965", country: "Kuwait" },
  { code: "+20", country: "Egypt" },
  { code: "+93", country: "Afghanistan" },
  { code: "+355", country: "Albania" },
  { code: "+213", country: "Algeria" },
  { code: "+1", country: "United States" },
  { code: "+44", country: "United Kingdom" },
  { code: "+91", country: "India" },
  { code: "+86", country: "China" },
  { code: "+81", country: "Japan" },
  { code: "+82", country: "South Korea" },
  { code: "+49", country: "Germany" },
  { code: "+33", country: "France" },
  { code: "+39", country: "Italy" },
  { code: "+7", country: "Russia" },
  { code: "+55", country: "Brazil" },
  { code: "+52", country: "Mexico" },
  { code: "+61", country: "Australia" },
  { code: "+64", country: "New Zealand" },
  { code: "+27", country: "South Africa" },
  { code: "+234", country: "Nigeria" },
  { code: "+374", country: "Armenia" },
  { code: "+61", country: "Australia" },
  { code: "+43", country: "Austria" },
  { code: "+994", country: "Azerbaijan" },
  { code: "+973", country: "Bahrain" },
  { code: "+880", country: "Bangladesh" },
  { code: "+32", country: "Belgium" },
  { code: "+501", country: "Belize" },
  { code: "+975", country: "Bhutan" },
  { code: "+591", country: "Bolivia" },
  { code: "+267", country: "Botswana" },
  { code: "+359", country: "Bulgaria" },
  { code: "+855", country: "Cambodia" },
  { code: "+237", country: "Cameroon" },
  { code: "+1", country: "Canada" },
  { code: "+56", country: "Chile" },
  { code: "+57", country: "Colombia" },
  { code: "+506", country: "Costa Rica" },
  { code: "+385", country: "Croatia" },
  { code: "+53", country: "Cuba" },
  { code: "+357", country: "Cyprus" },
  { code: "+420", country: "Czech Republic" },
  { code: "+45", country: "Denmark" },
  { code: "+253", country: "Djibouti" },
  { code: "+593", country: "Ecuador" },
  { code: "+503", country: "El Salvador" },
  { code: "+372", country: "Estonia" },
  { code: "+679", country: "Fiji" },
  { code: "+358", country: "Finland" },
  { code: "+995", country: "Georgia" },
  { code: "+30", country: "Greece" },
  { code: "+502", country: "Guatemala" },
  { code: "+224", country: "Guinea" },
  { code: "+592", country: "Guyana" },
  { code: "+36", country: "Hungary" },
  { code: "+62", country: "Indonesia" },
  { code: "+98", country: "Iran" },
  { code: "+964", country: "Iraq" },
  { code: "+353", country: "Ireland" },
  { code: "+972", country: "Israel" },
  { code: "+962", country: "Jordan" },
  { code: "+254", country: "Kenya" },
  { code: "+996", country: "Kyrgyzstan" },
  { code: "+856", country: "Laos" },
  { code: "+961", country: "Lebanon" },
  { code: "+218", country: "Libya" },
  { code: "+352", country: "Luxembourg" },
  { code: "+60", country: "Malaysia" },
  { code: "+960", country: "Maldives" },
  { code: "+52", country: "Mexico" },
  { code: "+976", country: "Mongolia" },
  { code: "+212", country: "Morocco" },
  { code: "+95", country: "Myanmar" },
  { code: "+31", country: "Netherlands" },
  { code: "+64", country: "New Zealand" },
  { code: "+234", country: "Nigeria" },
  { code: "+47", country: "Norway" },
  { code: "+968", country: "Oman" },
  { code: "+92", country: "Pakistan" },
  { code: "+507", country: "Panama" },
  { code: "+595", country: "Paraguay" },
  { code: "+51", country: "Peru" },
  { code: "+63", country: "Philippines" },
  { code: "+48", country: "Poland" },
  { code: "+351", country: "Portugal" },
  { code: "+974", country: "Qatar" },
  { code: "+40", country: "Romania" },
  { code: "+7", country: "Russia" },
  { code: "+966", country: "Saudi Arabia" },
  { code: "+65", country: "Singapore" },
  { code: "+421", country: "Slovakia" },
  { code: "+386", country: "Slovenia" },
  { code: "+27", country: "South Africa" },
  { code: "+82", country: "South Korea" },
  { code: "+34", country: "Spain" },
  { code: "+94", country: "Sri Lanka" },
  { code: "+46", country: "Sweden" },
  { code: "+41", country: "Switzerland" },
  { code: "+963", country: "Syria" },
  { code: "+886", country: "Taiwan" },
  { code: "+66", country: "Thailand" },
  { code: "+216", country: "Tunisia" },
  { code: "+90", country: "Turkey" },
  { code: "+256", country: "Uganda" },
  { code: "+380", country: "Ukraine" },
  { code: "+971", country: "United Arab Emirates" },
  { code: "+44", country: "United Kingdom" },
  { code: "+1", country: "United States" },
  { code: "+598", country: "Uruguay" },
  { code: "+998", country: "Uzbekistan" },
  { code: "+58", country: "Venezuela" },
  { code: "+84", country: "Vietnam" },
  { code: "+967", country: "Yemen" },
  { code: "+260", country: "Zambia" },
  { code: "+263", country: "Zimbabwe" },
];
const PhoneInput = React.memo(({ value, onChange, error }) => {
  const [localCountryCode, setLocalCountryCode] = React.useState(() => {
    if (value && value.startsWith('+')) {
      const code = countryCodes.find(c => value.startsWith(c.code));
      return code || countryCodes[0];
    }
    return countryCodes[0];
  });

  React.useEffect(() => {
    if (value && value.startsWith('+')) {
      const code = countryCodes.find(c => value.startsWith(c.code));
      if (code && code.code !== localCountryCode.code) {
        setLocalCountryCode(code);
      }
    }
  }, [value, localCountryCode.code]);

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={4}>
        <Autocomplete
          options={countryCodes}
          autoHighlight
          getOptionLabel={(option) => `${option.country} (${option.code})`}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.country} ({option.code})
            </Box>
          )}
          value={localCountryCode}
          onChange={(event, newValue) => {
            setLocalCountryCode(newValue);
            onChange(newValue.code + (value ? value.slice(localCountryCode.code.length) : ''));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country Code"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          fullWidth
          label="Phone Number"
          value={value && value.startsWith('+') ? value.slice(localCountryCode.code.length) : value || ''}
          onChange={(e) => onChange(localCountryCode.code + e.target.value)}
          error={!!error}
          helperText={error}
        />
      </Grid>
    </Grid>
  );
});

const Customers = () => {
  const navigate = useNavigate();

  const fields = useMemo(
    () => [
      {
        name: "firstName",
        label: "First Name",
        type: "text",
        required: true,
        searchable: true,
        filterable: true,
      },
      {
        name: "lastName",
        label: "Last Name",
        type: "text",
        required: true,
        searchable: true,
        filterable: true,
      },
      { 
        name: "email",
        label: "Email",
        type: "email",
        searchable: true,
        filterable: true,
      },
      { 
        name: 'phone', 
        label: 'Phone', 
        type: 'text', 
        required: true, 
        searchable: true, 
        filterable: true,
        render: (value, onChange, error) => (
          <PhoneInput value={value} onChange={onChange} error={error} />
        ),
        parse: (value) => value,
        format: (value) => value,
      },
      { name: "address", label: "Address", type: "text", searchable: true },
      {
        name: "viewProfile",
        label: "Profile",
        type: "custom",
        renderCell: (entity) => (
          <Button
            variant="outlined"
            startIcon={<VisibilityIcon />}
            onClick={() => navigate(`/customers/${entity.id}`)}
          >
            View Profile
          </Button>
        ),
      },
    ],
    []
  );

  const customerService = useMemo(
    () => ({
      getAll: async (params = {}) => {
        try {
          let url = '/api/customers?populate=*';
          if (params.page && params.pageSize) {
            url += `&pagination[page]=${params.page}&pagination[pageSize]=${params.pageSize}`;
          } else {
            url += '&pagination[pageSize]=-1'; // Fetch all customers for export
          }
          const response = await api.get(url);
          const formattedData = response.data.data.map((customer) => ({
            id: customer.id,
            ...customer.attributes,
          }));
          return {
            data: formattedData,
            meta: response.data.meta,
          };
        } catch (error) {
          console.error('Error fetching customers:', error);
          throw new Error('Error fetching customers');
        }
      },
      create: async (customerData) => {
        const response = await api.post("/api/customers", {
          data: customerData,
        });
        return response.data.data;
      },
      update: async (id, customerData) => {
        const response = await api.put(`/api/customers/${id}`, {
          data: customerData,
        });
        return response.data.data;
      },
      delete: async (id) => {
        await api.delete(`/api/customers/${id}`);
      },
    }),
    []
  );

  const customActions = useMemo(
    () => [
      {
        name: "View Profile",
        icon: "Visibility",
        handler: (customer) => {
          navigate(`/customers/${customer.id}`);
        },
      },
    ],
    [navigate]
  );
  const handleRowClick = (customer) => {
    navigate(`/customers/${customer.id}`);
  };


  return (
    <Layout>
      <Box sx={{ width: "100%", mb: 4 }}>
        <GenericEntityManager
          entityName="Customer"
          entityNamePlural="Customers"
          fields={fields}
          service={customerService}
          // customActions={customActions}
        />
      </Box>
    </Layout>
  );
};

export default Customers;
