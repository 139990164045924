import React, { useState, useMemo } from 'react';
import { Box, Snackbar, Alert } from '@mui/material';
import Layout from '../components/Layout';
import GenericEntityManager from '../components/GenericEntityManager';
import api from '../config/api';

const Branches = () => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fields = useMemo(() => [
    { 
      name: 'name', 
      label: 'Name', 
      type: 'text', 
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Name is required";
        return null;
      }
    },
    { 
      name: 'address', 
      label: 'Address', 
      type: 'text', 
      multiline: true,
      rows: 3,
      searchable: true,
      filterable: true
    },
    { 
      name: 'phone', 
      label: 'Phone', 
      type: 'text', 
      searchable: true,
      filterable: true
    },
    { 
      name: 'email', 
      label: 'Email', 
      type: 'email', 
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (value && !/\S+@\S+\.\S+/.test(value)) return "Invalid email format";
        return null;
      }
    },
  ], []);

  const branchService = useMemo(() => ({
    getAll: async (params = {}) => {
      try {
        let url = '/api/branches?populate=*';
        if (params.page && params.pageSize) {
          url += `&pagination[page]=${params.page}&pagination[pageSize]=${params.pageSize}`;
        } else {
          url += '&pagination[pageSize]=-1'; // Fetch all customers for export
        }
        const response = await api.get(url);
        const formattedData = response.data.data.map((branch) => ({
          id: branch.id,
          ...branch.attributes,
        }));
        return {
          data: formattedData,
          meta: response.data.meta,
        };
      } catch (error) {
        console.error('Error fetching branches:', error);
        throw new Error('Error fetching branches');
      }
    },
    create: async (branchData) => {
      try {
        const response = await api.post('/api/branches', { data: branchData });
        showSnackbar('Branch created successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error creating branch:', error.response?.data || error.message);
        showSnackbar('Error creating branch', 'error');
        throw error;
      }
    },
    update: async (id, branchData) => {
      try {
        const response = await api.put(`/api/branches/${id}`, { data: branchData });
        showSnackbar('Branch updated successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error updating branch:', error.response?.data || error.message);
        showSnackbar('Error updating branch', 'error');
        throw error;
      }
    },
    delete: async (id) => {
      try {
        await api.delete(`/api/branches/${id}`);
        showSnackbar('Branch deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting branch:', error);
        showSnackbar('Error deleting branch', 'error');
        throw error;
      }
    },
  }), []);

  return (
    <Layout>
      <Box sx={{ width: '100%', mb: 4 }}>
        <GenericEntityManager
          entityName="Branch"
          entityNamePlural="Branches"
          fields={fields}
          service={branchService}
        />
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Branches;