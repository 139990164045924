import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Grid, Box } from '@mui/material';

const countryCodes = [
  { code: "+965", country: "Kuwait" },
  { code: "+20", country: "Egypt" },
  { code: "+1", country: "United States" },
  { code: "+44", country: "United Kingdom" },
  // Add more country codes as needed
];

export const PhoneInput = React.memo(({ value, onChange, error, fullWidth }) => {
  const [localCountryCode, setLocalCountryCode] = useState(() => {
    if (value && value.startsWith('+')) {
      const code = countryCodes.find(c => value.startsWith(c.code));
      return code || countryCodes[0];
    }
    return countryCodes[0];
  });

  useEffect(() => {
    if (value && value.startsWith('+')) {
      const code = countryCodes.find(c => value.startsWith(c.code));
      if (code && code.code !== localCountryCode.code) {
        setLocalCountryCode(code);
      }
    }
  }, [value, localCountryCode.code]);

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={4}>
        <Autocomplete
          options={countryCodes}
          autoHighlight
          getOptionLabel={(option) => `${option.country} (${option.code})`}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.country} ({option.code})
            </Box>
          )}
          value={localCountryCode}
          onChange={(event, newValue) => {
            setLocalCountryCode(newValue);
            onChange(newValue.code + (value ? value.slice(localCountryCode.code.length) : ''));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country Code"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          fullWidth={fullWidth}
          label="Phone Number"
          value={value && value.startsWith('+') ? value.slice(localCountryCode.code.length) : value || ''}
          onChange={(e) => onChange(localCountryCode.code + e.target.value)}
          error={!!error}
          helperText={error}
        />
      </Grid>
    </Grid>
  );
});
