import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from './Auth/Login';
import Register from './Auth/Register';
import Dashboard from './Dashboard';
import Products from '../pages/Products';
import Customers from '../pages/Customers';
import Employees from '../pages/Employees';

import Inventory from '../pages/Inventory';
import Settings from './Settings';
import ProductDetails from './Products/ProductDetails';
import Services from '../pages/Services';
import PaymentMethods from '../pages/PaymentMethods';
import Currencies from '../pages/Currencies';
import Branches from '../pages/Branches';
import CustomerProfile from './Customers/CustomerProfile';
import Taxes from '../pages/Taxes';
import Appointments from '../pages/Appointments';
import Orders from '../pages/Orders/Orders';
import OrderDetails from '../pages/Orders/OrderDetails'

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      <Route path="/products" element={<PrivateRoute><Products /></PrivateRoute>} />
      <Route path="/products/:id" element={<PrivateRoute><ProductDetails /></PrivateRoute>} />
      <Route path="/customers" element={<PrivateRoute><Customers /></PrivateRoute>} />
      <Route path="/customers/:id" element={<PrivateRoute><CustomerProfile /></PrivateRoute>} />
      <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
      <Route path="/orders/:id" element={<PrivateRoute><OrderDetails /></PrivateRoute>} />
      <Route path="/appointments" element={<PrivateRoute><Appointments /></PrivateRoute>} />
      <Route path="/employees" element={<PrivateRoute><Employees /></PrivateRoute>} />
      <Route path="/inventory" element={<PrivateRoute><Inventory /></PrivateRoute>} />
      <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
      <Route path="/services" element={<PrivateRoute><Services /></PrivateRoute>} />
      <Route path="/taxes" element={<PrivateRoute><Taxes /></PrivateRoute>} />
      <Route path="/payment-methods" element={<PrivateRoute><PaymentMethods /></PrivateRoute>} />
      <Route path="/branches" element={<PrivateRoute><Branches /></PrivateRoute>} />
      <Route path="/currencies" element={<PrivateRoute><Currencies /></PrivateRoute>} />
    </Routes>
  );
};

export default AppRoutes;