import React, { useState, useMemo } from 'react';
import { Box, Snackbar, Alert } from '@mui/material';
import Layout from '../components/Layout';
import GenericEntityManager from '../components/GenericEntityManager';
import api from '../config/api';

const Currencies = () => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fields = useMemo(() => [
    { 
      name: 'name', 
      label: 'Name', 
      type: 'text', 
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Name is required";
        return null;
      }
    },
    { 
      name: 'code', 
      label: 'Code', 
      type: 'text', 
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Code is required";
        return null;
      }
    },
    { 
      name: 'symbol', 
      label: 'Symbol', 
      type: 'text', 
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Symbol is required";
        return null;
      }
    },
    { 
      name: 'exchangeRate', 
      label: 'Exchange Rate', 
      type: 'number', 
      required: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Exchange rate is required";
        if (value <= 0) return "Exchange rate must be positive";
        return null;
      }
    },
    { 
      name: 'isDefault', 
      label: 'Default Currency', 
      type: 'boolean', 
      filterable: true
    },
  ], []);

  const currencyService = useMemo(() => ({
    getAll: async (params = {}) => {
      try {
        let url = '/api/currencies?populate=*';
        if (params.page && params.pageSize) {
          url += `&pagination[page]=${params.page}&pagination[pageSize]=${params.pageSize}`;
        } else {
          url += '&pagination[pageSize]=-1'; // Fetch all customers for export
        }
        const response = await api.get(url);
        const formattedData = response.data.data.map((currency) => ({
          id: currency.id,
          ...currency.attributes,
        }));
        return {
          data: formattedData,
          meta: response.data.meta,
        };
      } catch (error) {
        console.error('Error fetching currencies:', error);
        throw new Error('Error fetching currencies');
      }
    },
    create: async (currencyData) => {
      try {
        const response = await api.post('/api/currencies', { data: currencyData });
        showSnackbar('Currency created successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error creating currency:', error.response?.data || error.message);
        showSnackbar('Error creating currency', 'error');
        throw error;
      }
    },
    update: async (id, currencyData) => {
      try {
        const response = await api.put(`/api/currencies/${id}`, { data: currencyData });
        showSnackbar('Currency updated successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error updating currency:', error.response?.data || error.message);
        showSnackbar('Error updating currency', 'error');
        throw error;
      }
    },
    delete: async (id) => {
      try {
        await api.delete(`/api/currencies/${id}`);
        showSnackbar('Currency deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting currency:', error);
        showSnackbar('Error deleting currency', 'error');
        throw error;
      }
    },
  }), []);

  return (
    <Layout>
      <Box sx={{ width: '100%', mb: 4 }}>
        <GenericEntityManager
          entityName="Currency"
          entityNamePlural="Currencies"
          fields={fields}
          service={currencyService}
        />
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Currencies;