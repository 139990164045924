import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:1337';

const api = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor
api.interceptors.request.use(function (config) {
  // Don't add Authorization header for login requests
  if (!config.url.includes('/auth/local')) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  
  // If it's a FormData request, let the browser set the Content-Type
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    config.headers['Content-Type'] = 'application/json';
  }
  
  return config;
}, function (error) {
  return Promise.reject(error);
});



export default api;

