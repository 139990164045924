import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Grid,
  FormHelperText,
  Button,
  Avatar,
  FormControlLabel,
  Switch,
  Paper,
  Typography
} from "@mui/material";
import { DatePicker, TimePicker, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format, parseISO } from "date-fns";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const GenericForm = forwardRef(
  ({ fields, initialData, onSubmit, relationData }, ref) => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [imagePreview, setImagePreview] = useState({});
  

    useEffect(() => {
        if (initialData) {
            const formattedData = { ...initialData };
            fields.forEach(field => {
              if (field.type === 'datetime' && formattedData[field.name]) {
                formattedData[field.name] = new Date(formattedData[field.name]);
              }
            });
            setFormData(formattedData);
            // Initialize image previews for existing images
            const initialImagePreviews = {};
            fields.forEach(field => {
              if (field.type === 'image' && initialData[field.name]) {
                initialImagePreviews[field.name] = initialData[field.name];
              }
            });
            setImagePreview(initialImagePreviews);
          } else {
            // Initialize empty form data
            const emptyFormData = {};
            fields.forEach(field => {
              emptyFormData[field.name] = field.defaultValue !== undefined ? field.defaultValue : '';
            });
            setFormData(emptyFormData);
          }
        }, [initialData, fields]);
    
      
        const handleChange = (name, value) => {
          setFormData(prevData => ({ ...prevData, [name]: value }));
          if (errors[name]) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: null }));
          }
      
          if (value instanceof File) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(prev => ({ ...prev, [name]: reader.result }));
            };
            reader.readAsDataURL(value);
          }
        };

        
    const validateForm = () => {
      const newErrors = {};
      fields.forEach((field) => {
        if (
          field.required &&
          (formData[field.name] === undefined ||
            formData[field.name] === null ||
            formData[field.name] === "")
        ) {
          newErrors[field.name] = "This field is required";
        }
        if (field.validate) {
          const error = field.validate(formData[field.name]);
          if (error) {
            newErrors[field.name] = error;
          }
        }
      });
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    
    const handleSubmit = async () => {
      if (validateForm()) {
        const formattedData = {};
        fields.forEach((field) => {
          if (field.type === "date") {
            formattedData[field.name] = formData[field.name]
              ? format(formData[field.name], "yyyy-MM-dd")
              : null;
          } else if (field.type === "datetime") {
            formattedData[field.name] = formData[field.name]
              ? format(formData[field.name], "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
              : null;
          } else if (field.type === "relation") {
            formattedData[field.name] = formData[field.name];
          } else {
            formattedData[field.name] = formData[field.name];
          }
        });
        await onSubmit(formattedData);
      }
    };
    

    useImperativeHandle(ref, () => ({
      handleSubmit,
      validateForm,
    }));

    const renderField = (field) => {
      if (field.renderCustomField) {
        return field.renderCustomField(
          formData[field.name],
          (value) => handleChange(field.name, value),
          errors[field.name],
          relationData[field.relationTarget],
          formData  // Pass the entire formData to the custom field renderer
        );
      }
  


      if (field.render) {
        return field.render(
          formData[field.name],
          (value) => handleChange(field.name, value),
          errors[field.name]
        );
      }
      switch (field.type) {
        case "datetime":
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label={field.label}
                value={formData[field.name] || null}
                onChange={(newValue) => handleChange(field.name, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors[field.name]}
                    helperText={errors[field.name]}
                    required={field.required}
                  />
                )}
              />
            </LocalizationProvider>
          );

        case "date":
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={field.label}
                value={formData[field.name] || null}
                onChange={(newValue) => handleChange(field.name, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors[field.name]}
                    helperText={errors[field.name]}
                    required={field.required}
                  />
                )}
              />
            </LocalizationProvider>
          );
          case "relation":
          return (
            <FormControl fullWidth error={!!errors[field.name]}>
              <InputLabel>{field.label}</InputLabel>
              <Select
                value={formData[field.name] || ""}
                onChange={(e) => handleChange(field.name, e.target.value)}
                label={field.label}
                required={field.required}
              >
                {(relationData[field.relationTarget] || []).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {errors[field.name] && (
                <FormHelperText>{errors[field.name]}</FormHelperText>
              )}
            </FormControl>
          );
          case "boolean":
          return (
            <FormControlLabel
              control={
                <Switch
                  checked={formData[field.name] || false}
                  onChange={(e) => handleChange(field.name, e.target.checked)}
                  name={field.name}
                />
              }
              label={field.label}
            />
          );
  
        case "select":
          return (
            <FormControl fullWidth error={!!errors[field.name]}>
              <InputLabel>{field.label}</InputLabel>
              <Select
                value={formData[field.name] || ""}
                onChange={(e) => handleChange(field.name, e.target.value)}
                label={field.label}
                required={field.required}
              >
                {field.options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {errors[field.name] && (
                <FormHelperText>{errors[field.name]}</FormHelperText>
              )}
            </FormControl>
          );
        case "text":
        case "email":
        case "password":
        case "number":
          return (
            <TextField
              fullWidth
              label={field.label}
              type={field.type}
              value={formData[field.name] || ""}
              onChange={(e) => handleChange(field.name, e.target.value)}
              error={!!errors[field.name]}
              helperText={errors[field.name]}
              required={field.required}
              multiline={field.multiline}
              rows={field.rows}
            />
          );
          case 'image':
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id={`image-upload-${field.name}`}
        type="file"
        onChange={(e) => handleChange(field.name, e.target.files[0])}
      />
      <label htmlFor={`image-upload-${field.name}`}>
        <Button
          variant="outlined"
          component="span"
          startIcon={<CloudUploadIcon />}
          fullWidth
        >
          Upload {field.label}
        </Button>
      </label>
      {(imagePreview[field.name] || formData[field.name]) && (
        <Avatar
          src={imagePreview[field.name] || formData[field.name] || ''}
          alt="Preview"
          sx={{ width: 100, height: 100, mt: 2 }}
        />
      )}
      {errors[field.name] && (
        <FormHelperText error>{errors[field.name]}</FormHelperText>
      )}
    </Box>
  );

    

        case "relation":
          return (
            <FormControl fullWidth error={!!errors[field.name]}>
              <InputLabel>{field.label}</InputLabel>
              <Select
                value={formData[field.name] || ""}
                onChange={(e) => handleChange(field.name, e.target.value)}
                label={field.label}
                required={field.required}
              >
                {(relationData[field.relationTarget] || []).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              {errors[field.name] && (
                <FormHelperText>{errors[field.name]}</FormHelperText>
              )}
            </FormControl>
          );
        default:
          return null;
      }
    };

    return (
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 3, fontWeight: 600, color: 'primary.main' }}>
          {initialData ? 'Edit' : 'Add New'} {fields[0]?.label || 'Item'}
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Grid container spacing={2}>
  {fields.map((field) => (
    <Grid item xs={12} sm={field.fullWidth ? 12 : 6} key={field.name}>
      <Box sx={{ mb: 2 }}>
        {renderField(field)}
      </Box>
    </Grid>
  ))}
</Grid>


        </form>
      </Paper>
    );
  }
);

export default GenericForm;
