// import React from 'react';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
// import { AuthProvider } from './contexts/AuthContext';
// import AppRoutes from './components/AppRoutes';
// import theme from './theme';

// // const theme = createTheme({
// //   palette: {
// //     primary: {
// //       main: '#1976d2',
// //     },
// //     secondary: {
// //       main: '#dc004e',
// //     },
// //   },
// // });

// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Router>
//         <AuthProvider>
//           <AppRoutes />
//         </AuthProvider>
//       </Router>
//     </ThemeProvider>
//   );
// }

// export default App;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import AppRoutes from './components/AppRoutes';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';

import { AuthProvider } from './contexts/AuthContext';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/*"
            element={
              // <Layout>
                <AppRoutes />
              // </Layout>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
    </ThemeProvider>

  );
};

export default App;
