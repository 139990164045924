import React, { useState, useMemo } from 'react';
import { Box, Snackbar, Alert } from '@mui/material';
import Layout from '../components/Layout';
import GenericEntityManager from '../components/GenericEntityManager';
import api from '../config/api';

const PaymentMethods = () => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fields = useMemo(() => [
    { 
      name: 'name', 
      label: 'Name', 
      type: 'text', 
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Name is required";
        return null;
      }
    },
    { 
      name: 'code', 
      label: 'Code', 
      type: 'text', 
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Code is required";
        return null;
      }
    },
    { 
      name: 'description', 
      label: 'Description', 
      type: 'text', 
      multiline: true,
      rows: 4,
      searchable: true,
      filterable: true
    },
    { 
      name: 'isActive', 
      label: 'Active', 
      type: 'boolean', 
      filterable: true
    },
    { 
      name: 'processingFee', 
      label: 'Processing Fee (%)', 
      type: 'number', 
      required: true,
      filterable: true,
      validate: (value) => {
        if (value === undefined || value === null) return "Processing fee is required";
        if (value < 0) return "Processing fee cannot be negative";
        return null;
      }
    },
  ], []);

  const paymentMethodService = useMemo(() => ({
    getAll: async (params = {}) => {
      try {
        let url = '/api/payment-methods?populate=*';
        if (params.page && params.pageSize) {
          url += `&pagination[page]=${params.page}&pagination[pageSize]=${params.pageSize}`;
        } else {
          url += '&pagination[pageSize]=-1'; // Fetch all customers for export
        }
        const response = await api.get(url);
        const formattedData = response.data.data.map((method) => ({
          id: method.id,
          ...method.attributes,
        }));
        return {
          data: formattedData,
          meta: response.data.meta,
        };
      } catch (error) {
        console.error('Error fetching payment-methods:', error);
        throw new Error('Error fetching payment-methods');
      }
    },
    create: async (methodData) => {
      try {
        const response = await api.post('/api/payment-methods', { data: methodData });
        showSnackbar('Payment method created successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error creating payment method:', error.response?.data || error.message);
        showSnackbar('Error creating payment method', 'error');
        throw error;
      }
    },
    update: async (id, methodData) => {
      try {
        const response = await api.put(`/api/payment-methods/${id}`, { data: methodData });
        showSnackbar('Payment method updated successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error updating payment method:', error.response?.data || error.message);
        showSnackbar('Error updating payment method', 'error');
        throw error;
      }
    },
    delete: async (id) => {
      try {
        await api.delete(`/api/payment-methods/${id}`);
        showSnackbar('Payment method deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting payment method:', error);
        showSnackbar('Error deleting payment method', 'error');
        throw error;
      }
    },
  }), []);

  return (
    <Layout>
      <Box sx={{ width: '100%', mb: 4 }}>
        <GenericEntityManager
          entityName="Payment Method"
          entityNamePlural="Payment Methods"
          fields={fields}
          service={paymentMethodService}
        />
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default PaymentMethods;