import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Typography, Stepper, Step, StepLabel,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Select, MenuItem, CircularProgress, Box
} from '@mui/material';
import { Upload, Save } from '@mui/icons-material';
import Papa from 'papaparse';

const steps = ['Upload File', 'Map Fields', 'Confirm and Import'];

const ImportDialog = ({ open, onClose, fields, service }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [mappings, setMappings] = useState({});
  const [previewData, setPreviewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    Papa.parse(uploadedFile, {
      complete: (results) => {
        setHeaders(results.data[0]);
        setPreviewData(results.data.slice(1, 6)); // Preview first 5 rows
        setActiveStep(1);
      },
      error: (error) => {
        setError('Error parsing CSV file');
        console.error('Error parsing CSV:', error);
      }
    });
  };

  const handleMapping = (header, field) => {
    setMappings({ ...mappings, [header]: field });
  };

  const handleImport = async () => {
    setIsLoading(true);
    setError('');
    try {
      if (service.import) {
        await service.import(file, mappings);
      } else {
        // Fallback to generic import if service.import is not available
        const results = await new Promise((resolve) => {
          Papa.parse(file, {
            header: true,
            complete: resolve
          });
        });

        const importedData = results.data.map(row => {
          const mappedRow = {};
          Object.keys(mappings).forEach(header => {
            mappedRow[mappings[header]] = row[header];
          });
          return mappedRow;
        });

        for (const data of importedData) {
          await service.create(data);
        }
      }
      setActiveStep(2);
    } catch (error) {
      setError('Import failed. Please try again.');
      console.error('Import failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <input
              accept=".csv"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span" startIcon={<Upload />}>
                Upload CSV
              </Button>
            </label>
          </Box>
        );
      case 1:
        return (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>CSV Header</TableCell>
                  <TableCell>Model Field</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {headers.map((header) => (
                  <TableRow key={header}>
                    <TableCell>{header}</TableCell>
                    <TableCell>
                      <Select
                        value={mappings[header] || ''}
                        onChange={(e) => handleMapping(header, e.target.value)}
                      >
                        {fields.map((field) => (
                          <MenuItem key={field.name} value={field.name}>{field.label}</MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      case 2:
        return (
          <Typography>
            Import completed successfully!
          </Typography>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Import Data</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent(activeStep)}
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {activeStep === 1 && (
          <Button
            variant="contained"
            onClick={handleImport}
            startIcon={isLoading ? <CircularProgress size={24} /> : <Save />}
            disabled={isLoading}
          >
            {isLoading ? 'Importing...' : 'Import Data'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;