import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  Tabs, 
  Tab, 
  Alert, 
  CircularProgress, 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Autocomplete,
  TextField
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseISO, format, isValid } from 'date-fns';
import Layout from '../components/Layout';
import api from '../config/api';
import GenericEntityManager from '../components/GenericEntityManager';

const Inventory = () => {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openReorderDialog, setOpenReorderDialog] = useState(false);
  const [reorderItems, setReorderItems] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [relationData, setRelationData] = useState({
    products: [],
    branches: [],
    employees: [],
    inventories: []
  });
  const [productsPagination, setProductsPagination] = useState({ page: 1, pageSize: 25, total: 0 });
  const [inventoriesPagination, setInventoriesPagination] = useState({ page: 1, pageSize: 25, total: 0 });
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);


  
const fetchRelationData = useCallback(async () => {
  try {
    const [productsRes, branchesRes, employeesRes, inventoriesRes] = await Promise.all([
      api.get('/api/products?pagination[page]=1&pagination[pageSize]=25'),
      api.get('/api/branches'),
      api.get('/api/users'),
      api.get('/api/inventories?populate=*&pagination[page]=1&pagination[pageSize]=25')
    ]);
    setRelationData({
      products: productsRes.data.data,
      branches: branchesRes.data.data.map(b => ({ id: b.id, name: b.attributes.name })),
      employees: employeesRes.data.map(e => ({ id: e.id, name: `${e.firstName} ${e.lastName}` })),
      inventories: inventoriesRes.data.data.map(i => ({ 
        id: i.id, 
        name: `${i.attributes.product.data.attributes.name} - ${i.attributes.branch.data.attributes.name}`
      }))
    });
    setProductsPagination({
      page: productsRes.data.meta.pagination.page,
      pageSize: productsRes.data.meta.pagination.pageSize,
      total: productsRes.data.meta.pagination.total
    });
    setInventoriesPagination({
      page: inventoriesRes.data.meta.pagination.page,
      pageSize: inventoriesRes.data.meta.pagination.pageSize,
      total: inventoriesRes.data.meta.pagination.total
    });
    setLoading(false);
  } catch (error) {
    console.error('Error fetching relation data:', error);
    setError('Failed to fetch data. Please try again later.');
    setLoading(false);
  }
}, []);

  useEffect(() => {
    fetchRelationData();
  }, [fetchRelationData]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const formatDate = (date) => {
    if (!date) return null;
    return date instanceof Date ? format(date, 'yyyy-MM-dd') : date;
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const parsedDate = parseISO(dateString);
    return isValid(parsedDate) ? parsedDate : null;
  };

  const formatDateTime = (date) => {
    if (!date) return null;
    return date instanceof Date ? format(date, "yyyy-MM-dd'T'HH:mm:ss") : date;
  };

  const parseDateTime = (dateTimeString) => {
    if (!dateTimeString) return null;
    const parsedDate = parseISO(dateTimeString);
    return isValid(parsedDate) ? parsedDate : null;
  };

  const fetchMoreProducts = useCallback(async (searchValue, isLoadMore = false) => {
    setIsLoadingProducts(true);
    const nextPage = isLoadMore ? productsPagination.page + 1 : 1;
    let url = `/api/products?pagination[page]=${nextPage}&pagination[pageSize]=${productsPagination.pageSize}`;
    
    if (searchValue) {
      url += `&filters[$or][0][name][$containsi]=${encodeURIComponent(searchValue)}`;
      url += `&filters[$or][1][code][$containsi]=${encodeURIComponent(searchValue)}`;
    }
    
    try {
      const response = await api.get(url);
      const newProducts = response.data.data;
      
      setRelationData(prev => ({
        ...prev,
        products: isLoadMore ? [...prev.products, ...newProducts] : newProducts
      }));
      
      setProductsPagination(prev => ({
        ...prev,
        page: nextPage,
        total: response.data.meta.pagination.total
      }));
      
      setIsLoadingProducts(false);
      return newProducts;
    } catch (error) {
      console.error('Error fetching products:', error);
      setIsLoadingProducts(false);
      return [];
    }
  }, [productsPagination.page, productsPagination.pageSize]);

  
  const fetchMoreInventories = async (searchValue) => {
    const nextPage = inventoriesPagination.page + 1;
    let url = `/api/inventories?populate=*&pagination[page]=${nextPage}&pagination[pageSize]=${inventoriesPagination.pageSize}`;
    if (searchValue) {
      url += `&filters[$or][0][product][name][$containsi]=${searchValue}&filters[$or][1][product][code][$containsi]=${searchValue}`;
    }
    const response = await api.get(url);
    const newInventories = response.data.data.map(i => ({ 
      id: i.id, 
      name: `${i.attributes.product.data.attributes.name} - ${i.attributes.branch.data.attributes.name}`
    }));
    setRelationData(prev => ({
      ...prev,
      inventories: [...prev.inventories, ...newInventories]
    }));
    setInventoriesPagination(prev => ({
      ...prev,
      page: nextPage
    }));
    return newInventories;
  };
  const productField = useMemo(() => ({
    name: 'product',
    label: 'Product',
    type: 'relation',
    relationTarget: 'products',
    required: true,
    searchable: true,
    filterable: true,
    disabled: (formMode) => formMode === 'edit',
    renderCustomField: (value, onChange, error, options, formData) => {
      const selectedProduct = options.find(p => p.id === value);

      return (
        <Autocomplete
          options={options || []}
          getOptionLabel={(option) => 
            option.attributes ? `${option.attributes.code || 'No Code'} - ${option.attributes.name || 'Unnamed Product'}` : option.name
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Product"
              error={!!error}
              helperText={error}
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingProducts ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          value={selectedProduct || null}
          onChange={(event, newValue) => {
            onChange(newValue ? newValue.id : null);
          }}
          onInputChange={(event, newInputValue, reason) => {
            if (reason === 'input') {
              setProductSearchTerm(newInputValue);
              fetchMoreProducts(newInputValue);
            }
          }}
          filterOptions={(options, { inputValue }) => {
            const filtered = options.filter(option => 
              (option.attributes?.code || '').toLowerCase().includes(inputValue.toLowerCase()) || 
              (option.attributes?.name || '').toLowerCase().includes(inputValue.toLowerCase())
            );
            
            if (inputValue !== '' && options.length < productsPagination.total) {
              filtered.push({
                name: `Load more "${inputValue}"`,
                id: 'load-more'
              });
            }
            
            return filtered;
          }}
          renderOption={(props, option) => {
            if (option.id === 'load-more') {
              return (
                <li {...props} style={{ fontStyle: 'italic', color: 'blue' }}>
                  {option.name}
                </li>
              );
            }
            return <li {...props}>{`${option.attributes?.code || 'No Code'} - ${option.attributes?.name || 'Unnamed Product'}`}</li>;
          }}
          loading={isLoadingProducts}
          loadingText="Loading products..."
          ListboxProps={{
            onScroll: (event) => {
              const listboxNode = event.currentTarget;
              if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                if (options.length < productsPagination.total) {
                  fetchMoreProducts(productSearchTerm, true);
                }
              }
            },
            style: { maxHeight: '200px' }
          }}
        />
      );
    },
    renderCell: (entity) => {
      const product = entity.product?.data?.attributes;
      return product ? `${product.code || 'No Code'} - ${product.name || 'Unnamed Product'}` : 'N/A';
    },
  }), [isLoadingProducts, productSearchTerm, productsPagination.total, fetchMoreProducts]);


  

  const inventoryFields = useMemo(() => [
    productField,
  
  
    { 
      name: 'branch', 
      label: 'Branch', 
      type: 'relation', 
      relationTarget: 'branches',
      required: true, 
      searchable: true, 
      filterable: true 
    },
    { 
      name: 'quantityOnHand', 
      label: 'Quantity On Hand', 
      type: 'number', 
      required: true,
      validate: (value) => value < 0 ? 'Quantity cannot be negative' : null
    },
    { 
      name: 'lastRestockDate', 
      label: 'Last Restock Date', 
      type: 'date', 
      required: false,
      parse: parseDate,
      format: formatDate,
    },
    { 
      name: 'reorderPoint', 
      label: 'Reorder Point', 
      type: 'number', 
      required: false,
      validate: (value) => value < 0 ? 'Reorder point cannot be negative' : null
    },
  ], [productsPagination.total]);

  const transactionFields = useMemo(() => [
    { 
      name: 'inventory', 
      label: 'Inventory Item', 
      type: 'relation', 
      relationTarget: 'inventories',
      required: true, 
      searchable: true, 
      filterable: true,
      renderCustomField: (value, onChange, error, options) => (
        <Autocomplete
          options={options || []}
          getOptionLabel={(option) => 
            option.name || `${option.attributes?.product?.data?.attributes?.name || 'Unknown Product'} - ${option.attributes?.branch?.data?.attributes?.name || 'Unknown Branch'}`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Inventory Item"
              error={!!error}
              helperText={error}
              required
            />
          )}
          value={value ? options.find(i => i.id === value) : null}
          onChange={(event, newValue) => {
            onChange(newValue ? newValue.id : null);
          }}
          onInputChange={(event, newInputValue) => {
            if (newInputValue) {
              fetchMoreInventories(newInputValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = options.filter(option => 
              option.name.toLowerCase().includes(params.inputValue.toLowerCase())
            );
      
            if (params.inputValue !== '' && filtered.length < inventoriesPagination.total) {
              filtered.push({
                name: `Load more "${params.inputValue}"`,
                id: 'load-more'
              });
            }
      
            return filtered;
          }}
          renderOption={(props, option) => {
            if (option.id === 'load-more') {
              return (
                <li {...props} style={{ fontStyle: 'italic', color: 'blue' }}>
                  {option.name}
                </li>
              );
            }
            return <li {...props}>{option.name}</li>;
          }}
          loading={options.length === 0}
          loadingText="Loading inventory items..."
          ListboxProps={{
            onScroll: (event) => {
              const listboxNode = event.currentTarget;
              if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                if (options.length < inventoriesPagination.total) {
                  fetchMoreInventories('', true);
                }
              }
            },
            style: { maxHeight: '200px' }
          }}
        />
      ),
      
      renderCell: (entity) => {
        const inventory = relationData.inventories.find(i => i.id === entity.inventory);
        return inventory ? inventory.name : 'N/A';
      }
    },
    { 
      name: 'type', 
      label: 'Transaction Type', 
      type: 'select', 
      options: [
        { value: 'addition', label: 'Addition' },
        { value: 'subtraction', label: 'Subtraction' }
      ], 
      required: true, 
      filterable: true 
    },
    { 
      name: 'quantity', 
      label: 'Quantity', 
      type: 'number', 
      required: true,
      validate: (value) => value <= 0 ? 'Quantity must be greater than 0' : null
    },
    { 
      name: 'date', 
      label: 'Date', 
      type: 'datetime', 
      required: true,
      parse: parseDateTime,
      format: formatDateTime,
      defaultValue: new Date(), // Set default value to current date and time
    },
    { 
      name: 'reason', 
      label: 'Reason', 
      type: 'text', 
      required: true 
    },
    { 
      name: 'performedBy', 
      label: 'Performed By', 
      type: 'relation', 
      relationTarget: 'employees',
      required: false,
      searchable: true, 
      filterable: true,
      renderCell: (entity) => {
        const employee = relationData.employees.find(e => e.id === entity.performedBy);
        return employee ? employee.name : 'N/A';
      }
    },
  ], [relationData.products]);
  const customValidations = useMemo(() => ({
    product: async (value) => {
      if (!value) return; // Skip validation if no value
      const existingInventories = await api.get(`/api/inventories?filters[product][id][$eq]=${value}`);
      if (existingInventories.data.data.length > 0) {
        return "An inventory for this product already exists.";
      }
    },
  }), [inventoriesPagination.total]);
  
  
  const inventoryService = useMemo(() => ({
    getAll: async (params = {}) => {
      try {
        let url = '/api/inventories?populate=*';
        if (params.page && params.pageSize) {
          url += `&pagination[page]=${params.page}&pagination[pageSize]=${params.pageSize}`;
        } else {
          url += '&pagination[pageSize]=-1'; // Fetch all inventory items for export
        }
        const response = await api.get(url);
        const formattedData = response.data.data.map(inventory => ({
          id: inventory.id,
          ...inventory.attributes,
          product: {
            data: {
              id: inventory.attributes.product.data.id,
              attributes: inventory.attributes.product.data.attributes
            }
          },
          branch: inventory.attributes.branch.data.id,
          lastRestockDate: inventory.attributes.lastRestockDate ? parseDate(inventory.attributes.lastRestockDate) : null,
        }));
        return {
          data: formattedData,
          meta: response.data.meta,
        };
      } catch (error) {
        console.error('Error fetching inventory:', error);
        throw new Error('Error fetching inventory');
      }
    },  
    create: async (data) => {
   
    const combinationExists = relationData.inventories.some(
      inventory => inventory.product === data.product && inventory.branch === data.branch
    );

    if (combinationExists) {
      // Show error message if the combination exists
      setSnackbar({ open: true, message: "This product and branch combination already exists in the inventory", severity: "error" });
      return;
    }
    const formattedData = {
      ...data,
      lastRestockDate: data.lastRestockDate ? formatDate(data.lastRestockDate) : null,
    };

    try {
      const response = await api.post('/api/inventories', { data: formattedData });
      await fetchRelationData(); // Refresh data after the new entry is added
      setSnackbar({ open: true, message: "Inventory entry added successfully", severity: "success" });
      return response.data;
    } catch (error) {
      console.error("Error adding inventory entry:", error);
      setSnackbar({ open: true, message: "Failed to add inventory entry", severity: "error" });
    }



      // const formattedData = {
      //   ...data,
      //   lastRestockDate: data.lastRestockDate ? formatDate(data.lastRestockDate) : null,
      // };
      // const response = await api.post('/api/inventories', { data: formattedData });
      await fetchRelationData();
      // return response.data;
    },
    update: async (id, data) => {
      throw new Error("Updating inventory is not allowed");
    },

    delete: async (id) => {
      await api.delete(`/api/inventories/${id}`);
      await fetchRelationData();
    },
    export: async () => {
      const response = await api.get('/api/inventories/export', { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'inventories.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    import: async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      await api.post('/api/inventories/import', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      await fetchRelationData();
    }
  }), [fetchRelationData]);

  const transactionService = useMemo(() => ({
    getAll: async ({ page, pageSize }) => {
      try {
        const response = await api.get(`/api/inventory-transactions?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`);
        return {
          data: response.data.data.map(transaction => ({
            id: transaction.id,
            ...transaction.attributes,
            inventory: transaction.attributes.inventory.data?.id ?? null,
            performedBy: transaction.attributes.performedBy.data?.id ?? null,
            date: transaction.attributes.date ? parseDateTime(transaction.attributes.date) : null,
          })),
          meta: response.data.meta
        };
      } catch (error) {
        console.error('Error fetching inventory transactions:', error);
        throw error;
      }
    },
    create: async (data) => {
      const formattedData = {
        ...data,
        date: data.date ? formatDateTime(data.date) : null,
      };
      const response = await api.post('/api/inventory-transactions', { data: formattedData });
      
      // Update the inventory quantity
      const inventoryResponse = await api.get(`/api/inventories/${data.inventory}`);
      const inventory = inventoryResponse.data.data;
      const newQuantity = data.type === 'addition' 
        ? inventory.attributes.quantityOnHand + data.quantity
        : inventory.attributes.quantityOnHand - data.quantity;
      await api.put(`/api/inventories/${data.inventory}`, {
        data: { 
          quantityOnHand: newQuantity,
          lastRestockDate: data.type === 'addition' ? formatDate(new Date()) : inventory.attributes.lastRestockDate
        }
      });
      
      return response.data;
    },
    update: async (id, data) => {
      throw new Error("Updating transactions is not allowed");
    },
    delete: async (id) => {
      await api.delete(`/api/inventory-transactions/${id}`);
      await fetchRelationData();
    },
    export: async () => {
      const response = await api.get('/api/inventory-transactions/export', { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'inventory-transactions.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    import: async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      await api.post('/api/inventory-transactions/import', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    }
  }), []);

  const checkReorderPoints = useCallback(async () => {
    const inventories = await inventoryService.getAll();
    const itemsToReorder = inventories.filter(
      item => item.quantityOnHand <= item.reorderPoint
    );
    setReorderItems(itemsToReorder);
    if (itemsToReorder.length > 0) {
      setOpenReorderDialog(true);
    }
  }, [inventoryService]);

  const handleReorder = async () => {
    for (const item of reorderItems) {
      await transactionService.create({
        inventory: item.id,
        type: 'addition',
        quantity: item.reorderPoint - item.quantityOnHand + 10, // Reorder to 10 above reorder point
        date: new Date(),
        reason: 'Automatic reorder',
        performedBy: relationData.employees[0]?.id // Assign to the first employee in the list
      });
    }
    setOpenReorderDialog(false);
    fetchRelationData();
  };
  

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Layout>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Alert severity="error">{error}</Alert>
      </Layout>
    );
  }

  return (
    <Layout>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h4" gutterBottom>Inventory Management</Typography>
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Current Inventory" />
              <Tab label="Transaction History" />
            </Tabs>
            {/* <Button variant="contained" color="primary" onClick={checkReorderPoints}>
              Check Reorder Points
            </Button> */}
          </Box>

          <Box sx={{ display: tabValue === 0 ? 'block' : 'none' }}>
          <GenericEntityManager
              entityName="Inventory Item"
              entityNamePlural="Inventory Items"
              fields={inventoryFields}
              service={inventoryService}
              relationData={relationData}
              customValidations={customValidations}
              getRowId={(row) => `${row.product}-${row.branch}`} // Assuming product-branch combination is unique
            />
          </Box>

          <Box sx={{ display: tabValue === 1 ? 'block' : 'none' }}>
            <GenericEntityManager
              entityName="Inventory Transaction"
              entityNamePlural="Inventory Transactions"
              fields={transactionFields}
              service={transactionService}
              relationData={relationData}
            />
          </Box>
        </Box>

        <Dialog open={openReorderDialog} onClose={() => setOpenReorderDialog(false)}>
          <DialogTitle>Reorder Alert</DialogTitle>
          <DialogContent>
            <Typography>
              The following items need to be reordered:
            </Typography>
            <ul>
              {reorderItems.map(item => (
                <li key={item.id}>
                  {relationData.products.find(p => p.id === item.product)?.name} - 
                  Current quantity: {item.quantityOnHand}, 
                  Reorder point: {item.reorderPoint}
                </li>
              ))}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenReorderDialog(false)}>Cancel</Button>
            <Button onClick={handleReorder} variant="contained" color="primary">
              Reorder
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </Layout>
  );
};

export default Inventory;

