import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";

import api from "../../config/api";
import Layout from "../../components/Layout";

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [business, setBusiness] = useState(null);
  const componentRef = useRef();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchOrder();
    fetchBusiness();
  }, [id]);

  const fetchOrder = async () => {
    try {
      const response = await api.get(
        `/api/orders/${id}?populate[customer][populate]=*&populate[orderItems][populate]=*&populate[currency][populate]=*&populate[paymentMethod][populate]=*`
      );
      console.log("Fetched order:", response.data.data); // Add this line for debugging
      setOrder(response.data.data);
    } catch (error) {
      console.error("Error fetching order:", error);
      showSnackbar("Error fetching order details", "error");
    }
  };

  const fetchBusiness = async () => {
    try {
      const response = await api.get("/api/business?populate=*");
      setBusiness(response.data.data);
    } catch (error) {
      console.error("Error fetching business info:", error);
      showSnackbar("Error fetching business information", "error");
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this order?")) {
      try {
        await api.delete(`/api/orders/${id}`);
        showSnackbar("Order deleted successfully", "success");
        navigate("/orders");
      } catch (error) {
        console.error("Error deleting order:", error);
        showSnackbar("Error deleting order", "error");
      }
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (!order || !business) {
    return <Typography>Loading...</Typography>;
  }

  const Invoice = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <Box sx={{ p: 4, maxWidth: "800px", margin: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {business.attributes.logo?.data?.attributes?.url && (
              <img
                src={`${process.env.REACT_APP_API_URL}${business.attributes.logo.data.attributes.url}`}
                alt="Business Logo"
                style={{ maxWidth: "150px" }}
              />
            )}
            <Typography variant="h6">{business.attributes.name}</Typography>
            <Typography>{business.attributes.address}</Typography>
            <Typography>{business.attributes.phone}</Typography>
            <Typography>{business.attributes.email}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Typography variant="h4">INVOICE</Typography>
            <Typography>Order #: {order.attributes.orderNumber}</Typography>
            <Typography>
              Date: {new Date(order.attributes.orderDate).toLocaleDateString()}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Bill To:</Typography>
          <Typography>
            {order.attributes.customer?.data?.attributes?.firstName}{" "}
            {order.attributes.customer?.data?.attributes?.lastName}
          </Typography>
          <Typography>
            {order.attributes.customer?.data?.attributes?.email}
          </Typography>
          <Typography>
            {order.attributes.customer?.data?.attributes?.phone}
          </Typography>
          <Typography>
            {order.attributes.customer?.data?.attributes?.address}
          </Typography>
        </Box>

        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Unit Price</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.attributes.orderItems?.data?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.attributes.product?.data?.attributes?.name ||
                      item.attributes.service?.data?.attributes?.name ||
                      "N/A"}
                  </TableCell>
                  <TableCell align="right">
                    {item.attributes.quantity}
                  </TableCell>
                  <TableCell align="right">
                    {item.attributes.unitPrice?.toFixed(2)}{" "}
                    {order.attributes.currency?.data?.attributes?.symbol}
                  </TableCell>
                  <TableCell align="right">
                    {(
                      item.attributes.quantity * item.attributes.unitPrice
                    ).toFixed(2)}{" "}
                    {order.attributes.currency?.data?.attributes?.symbol}
                  </TableCell>
                </TableRow>
              )) || (
                <TableRow>
                  <TableCell colSpan={4}>No items found</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell colSpan={2}>Subtotal</TableCell>
                <TableCell align="right">
                  {order.attributes.subtotal}{" "}
                  {order.attributes.currency?.data?.attributes?.symbol}
                </TableCell>
              </TableRow>
              {(order.attributes.tax !== null && 
      order.attributes.tax !== undefined && 
      order.attributes.tax !== '' && 
      parseFloat(order.attributes.tax) > 0) && (
      <TableRow>
        <TableCell colSpan={2}>Tax</TableCell>
        <TableCell align="right">
          {parseFloat(order.attributes.tax).toFixed(2)}{" "}
          {order.attributes.currency?.data?.attributes?.symbol}
        </TableCell>
      </TableRow>
    )}
              <TableRow>
                <TableCell colSpan={2}>Total</TableCell>
                <TableCell align="right">
                  {order.attributes.totalAmount}{" "}
                  {order.attributes.currency?.data?.attributes?.symbol}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Payment Method:</Typography>
          <Typography>
            {order.attributes.paymentMethod?.data?.attributes?.name}
          </Typography>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="body2">Thank you for your business!</Typography>
          <Typography variant="body2">{business.attributes.name}</Typography>
          <Typography variant="body2">
            Tax ID: {business.attributes.taxId}
          </Typography>
        </Box>
      </Box>
    </div>
  ));

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h4" component="h1">
          Order Details
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrint}
            sx={{ mr: 2 }}
          >
            Print Invoice
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete Order
          </Button>
        </Box>
      </Box>

      <Invoice ref={componentRef} />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default OrderDetails;
