import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Snackbar, Alert } from '@mui/material';
import Layout from '../components/Layout';
import GenericEntityManager from '../components/GenericEntityManager';
import api from '../config/api';

const Services = () => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [relationData, setRelationData] = useState({
    currencies: []
  });

  useEffect(() => {
    fetchRelationData();
  }, []);

  const fetchRelationData = async () => {
    try {
      const currenciesRes = await api.get('/api/currencies');
      setRelationData({
        currencies: currenciesRes.data.data.map(c => ({ id: c.id, name: c.attributes.name }))
      });
    } catch (error) {
      console.error('Error fetching relation data:', error);
      showSnackbar('Error fetching relation data', 'error');
    }
  };

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fields = useMemo(() => [
    { 
      name: 'name', 
      label: 'Name', 
      type: 'text', 
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Name is required";
        return null;
      }
    },
    { 
      name: 'description', 
      label: 'Description', 
      type: 'text', 
      multiline: true,
      rows: 4,
      searchable: true,
      filterable: true
    },
    { 
      name: 'price', 
      label: 'Price', 
      type: 'number', 
      required: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Price is required";
        if (isNaN(value) || value <= 0) return "Price must be a positive number";
        return null;
      }
    },
    { 
      name: 'duration', 
      label: 'Duration (minutes)', 
      type: 'number', 
      required: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Duration is required";
        if (isNaN(value) || value <= 0) return "Duration must be a positive number";
        return null;
      }
    },
    { 
      name: 'currency', 
      label: 'Currency', 
      type: 'relation', 
      relationTarget: 'currencies',
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Currency is required";
        return null;
      }
    },
  ], []);

  const serviceService = useMemo(() => ({
    getAll: async (params = {}) => {
      try {
        let url = '/api/services?populate=*';
        if (params.page && params.pageSize) {
          url += `&pagination[page]=${params.page}&pagination[pageSize]=${params.pageSize}`;
        } else {
          url += '&pagination[pageSize]=-1'; // Fetch all services for export
        }
        const response = await api.get(url);
        const formattedData = response.data.data.map(service => ({
          id: service.id,
          ...service.attributes,
          currency: service.attributes.currency?.data?.id
        }));
        return {
          data: formattedData,
          meta: response.data.meta,
        };
      } catch (error) {
        console.error('Error fetching services:', error);
        throw new Error('Error fetching services');
      }
    },
    create: async (serviceData) => {
      try {
        const response = await api.post('/api/services', { data: serviceData });
        showSnackbar('Service created successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error creating service:', error.response?.data || error.message);
        showSnackbar('Error creating service', 'error');
        throw error;
      }
    },
    update: async (id, serviceData) => {
      try {
        const response = await api.put(`/api/services/${id}`, { data: serviceData });
        showSnackbar('Service updated successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error updating service:', error.response?.data || error.message);
        showSnackbar('Error updating service', 'error');
        throw error;
      }
    },
    delete: async (id) => {
      try {
        await api.delete(`/api/services/${id}`);
        showSnackbar('Service deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting service:', error);
        showSnackbar('Error deleting service', 'error');
        throw error;
      }
    },
  }), []);

  return (
    <Layout>
      <Box sx={{ width: '100%', mb: 4 }}>
        <GenericEntityManager
          entityName="Service"
          entityNamePlural="Services"
          fields={fields}
          service={serviceService}
          relationData={relationData}
        />
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Services;