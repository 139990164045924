import React, { useState, useEffect, useMemo } from 'react';
import {
  Box, Typography, Snackbar, Alert, Button, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, Grid, CircularProgress, Select, MenuItem, FormControl,
  InputLabel
} from '@mui/material';
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import api from '../config/api';
import Layout from '../components/Layout';
import GenericEntityManager from '../components/GenericEntityManager';
import { PhoneInput } from '../components/PhoneInput';

const Appointments = () => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [relationData, setRelationData] = useState({
    customers: [],
    employees: [],
    branches: []
  });
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);
  const [customerSearchQuery, setCustomerSearchQuery] = useState("");
  const [newCustomer, setNewCustomer] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
  });
  const [isSearching, setIsSearching] = useState(false);
  const [newAppointment, setNewAppointment] = useState(null);

  useEffect(() => {
    fetchRelationData();
  }, []);

  const fetchRelationData = async () => {
    try {
      const [customersRes, employeesRes, branchesRes] = await Promise.all([
        api.get('/api/customers'),
        api.get('/api/users'),
        api.get('/api/branches')
      ]);
      setRelationData({
        customers: customersRes.data.data.map(c => ({ 
          id: c.id, 
          name: `${c.attributes.firstName} ${c.attributes.lastName}` 
        })),
        employees: Array.isArray(employeesRes.data) 
          ? employeesRes.data.map(e => ({ id: e.id, name: e.username }))
          : employeesRes.data.data.map(e => ({ id: e.id, name: e.attributes.username })),
        branches: branchesRes.data.data.map(b => ({ 
          id: b.id, 
          name: b.attributes.name 
        }))
      });
    } catch (error) {
      console.error('Error fetching relation data:', error);
      showSnackbar('Error fetching relation data', 'error');
    }
  };

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCustomerSearch = async () => {
    setIsSearching(true);
    try {
      const encodedPhoneNumber = encodeURIComponent(customerSearchQuery);
      const response = await api.get(`/api/customers?filters[phone][$eq]=${encodedPhoneNumber}`);
      if (response.data.data && response.data.data.length > 0) {
        const customer = response.data.data[0];
        setIsCustomerDialogOpen(false);
        handleOpenNewAppointment(customer.id, `${customer.attributes.firstName} ${customer.attributes.lastName}`);
      } else {
        setNewCustomer(prev => ({ ...prev, phone: customerSearchQuery }));
      }
    } catch (error) {
      console.error("Error searching for customer:", error);
      showSnackbar("Error searching for customer", "error");
    } finally {
      setIsSearching(false);
    }
  };

  const handleCreateCustomer = async () => {
    try {
      const response = await api.post("/api/customers", { data: newCustomer });
      const createdCustomer = response.data.data;
      setIsCustomerDialogOpen(false);
      handleOpenNewAppointment(createdCustomer.id, `${createdCustomer.attributes.firstName} ${createdCustomer.attributes.lastName}`);
      showSnackbar("Customer created successfully", "success");
    } catch (error) {
      console.error("Error creating customer:", error);
      showSnackbar("Error creating customer", "error");
    }
  };

  const handleNewCustomerChange = (field, value) => {
    setNewCustomer({ ...newCustomer, [field]: value });
  };

  const handleOpenNewAppointment = (customerId, customerName) => {
    setNewAppointment({
      customer: customerId,
      customerName: customerName,
      dateTime: new Date(),
      employee: "",
      branch: "",
      status: "scheduled",
      notes: "",
    });
  };

  const handleCloseNewAppointment = () => {
    setNewAppointment(null);
  };

  const handleAppointmentInputChange = (e) => {
    const { name, value } = e.target;
    setNewAppointment(prev => ({ ...prev, [name]: value }));
  };

  const handleDateTimeChange = (newValue) => {
    setNewAppointment(prev => ({ ...prev, dateTime: newValue }));
  };

  const handleSubmitNewAppointment = async () => {
    try {
      await appointmentService.create(newAppointment);
      handleCloseNewAppointment();
      showSnackbar("Appointment created successfully", "success");
    } catch (error) {
      console.error("Error creating appointment:", error);
      showSnackbar("Error creating appointment", "error");
    }
  };
  const customActions = useMemo(() => [
    {
      name: "Create New Appointment",
      icon: AddIcon,
      handler: () => setIsCustomerDialogOpen(true),
    },
  ], []);



  const fields = useMemo(() => [
    { 
      name: 'dateTime', 
      label: 'Date & Time', 
      type: 'datetime', 
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Date and time is required";
        return null;
      }
    },
    { 
      name: 'customer', 
      label: 'Customer', 
      type: 'relation', 
      relationTarget: 'customers',
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Customer is required";
        return null;
      }
    },
    { 
      name: 'employee', 
      label: 'Employee', 
      type: 'relation', 
      relationTarget: 'employees',
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Employee is required";
        return null;
      }
    },
    { 
      name: 'branch', 
      label: 'Branch', 
      type: 'relation', 
      relationTarget: 'branches',
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Branch is required";
        return null;
      }
    },
    { 
      name: 'status', 
      label: 'Status', 
      type: 'select', 
      options: [
        { value: 'scheduled', label: 'Scheduled' },
        { value: 'confirmed', label: 'Confirmed' },
        { value: 'completed', label: 'Completed' },
        { value: 'cancelled', label: 'Cancelled' },
      ], 
      required: true,
      searchable: true,
      filterable: true,
      validate: (value) => {
        if (!value) return "Status is required";
        return null;
      }
    },
    { 
      name: 'notes', 
      label: 'Notes', 
      type: 'text', 
      multiline: true,
      searchable: true,
      filterable: true
    },
  ], []);

  const appointmentService = useMemo(() => ({
    getAll: async (params = {}) => {
      try {
        let url = '/api/appointments?populate=*';
        if (params.page && params.pageSize) {
          url += `&pagination[page]=${params.page}&pagination[pageSize]=${params.pageSize}`;
        } else {
          url += '&pagination[pageSize]=-1'; // Fetch all appointments for export
        }
        const response = await api.get(url);
        const formattedData = response.data.data.map(appointment => ({
          id: appointment.id,
          ...appointment.attributes,
          customer: appointment.attributes.customer?.data?.id,
          employee: appointment.attributes.employee?.data?.id,
          branch: appointment.attributes.branch?.data?.id,
        }));
        return {
          data: formattedData,
          meta: response.data.meta,
        };
      } catch (error) {
        console.error('Error fetching appointments:', error);
        throw new Error('Error fetching appointments');
      }
    },
    create: async (appointmentData) => {
      try {
        const response = await api.post('/api/appointments', { 
          data: appointmentData
        });
        showSnackbar('Appointment created successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error creating appointment:', error.response?.data || error.message);
        showSnackbar(`Error creating appointment: ${error.response?.data?.error?.message || error.message}`, 'error');
        throw error;
      }
    },
    update: async (id, appointmentData) => {
      try {
        const response = await api.put(`/api/appointments/${id}`, { 
          data: appointmentData
        });
        showSnackbar('Appointment updated successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error updating appointment:', error.response?.data || error.message);
        showSnackbar(`Error updating appointment: ${error.response?.data?.error?.message || error.message}`, 'error');
        throw error;
      }
    },
    delete: async (id) => {
      try {
        await api.delete(`/api/appointments/${id}`);
        showSnackbar('Appointment deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting appointment:', error);
        showSnackbar(`Error deleting appointment: ${error.message}`, 'error');
        throw error;
      }
    },
  }), []);

  return (
    <Layout>
      <Box sx={{ width: '100%', mb: 4 }}>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography variant="h4" component="h1">Appointments</Typography>
          <Button variant="contained" color="primary" onClick={() => setIsCustomerDialogOpen(true)}>
            Create New Appointment
          </Button>
        </Box> */}

        <GenericEntityManager
          entityName="Appointment"
          entityNamePlural="Appointments"
          fields={fields}
          service={appointmentService}
          relationData={relationData}
          customActions={customActions}

        />
      </Box>

      {/* Customer Search Dialog */}
      <Dialog open={isCustomerDialogOpen} onClose={() => setIsCustomerDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Find or Create Customer</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <PhoneInput
              value={customerSearchQuery}
              onChange={(value) => setCustomerSearchQuery(value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              onClick={handleCustomerSearch}
              disabled={isSearching || !customerSearchQuery}
              startIcon={isSearching ? <CircularProgress size={20} /> : <SearchIcon />}
              fullWidth
            >
              {isSearching ? "Searching..." : "Search"}
            </Button>
          </Box>
          <Typography variant="h6" gutterBottom>New Customer</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                value={newCustomer.firstName}
                onChange={(e) => handleNewCustomerChange("firstName", e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={newCustomer.lastName}
                onChange={(e) => handleNewCustomerChange("lastName", e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={newCustomer.email}
                onChange={(e) => handleNewCustomerChange("email", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                value={newCustomer.address}
                onChange={(e) => handleNewCustomerChange("address", e.target.value)}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCustomerDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleCreateCustomer} color="primary" variant="contained">
            Create Customer & Start Appointment
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Appointment Dialog */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Dialog open={newAppointment !== null} onClose={handleCloseNewAppointment} maxWidth="md" fullWidth>
          <DialogTitle>Create New Appointment</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <FormControl fullWidth disabled>
                  <InputLabel>Customer</InputLabel>
                  <Select
                    value={newAppointment?.customer || ""}
                    label="Customer"
                  >
                    <MenuItem value={newAppointment?.customer}>
                      {newAppointment?.customerName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  label="Date & Time"
                  value={newAppointment?.dateTime}
                  onChange={handleDateTimeChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Employee</InputLabel>
                  <Select
                    name="employee"
                    value={newAppointment?.employee || ""}
                    onChange={handleAppointmentInputChange}
                    label="Employee"
                  >
                    {relationData.employees.map((employee) => (
                      <MenuItem key={employee.id} value={employee.id}>
                        {employee.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
              <FormControl fullWidth>
                  <InputLabel>Branch</InputLabel>
                  <Select
                    name="branch"
                    value={newAppointment?.branch || ""}
                    onChange={handleAppointmentInputChange}
                    label="Branch"
                  >
                    {relationData.branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={newAppointment?.status || ""}
                    onChange={handleAppointmentInputChange}
                    label="Status"
                  >
                    <MenuItem value="scheduled">Scheduled</MenuItem>
                    <MenuItem value="confirmed">Confirmed</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                    <MenuItem value="cancelled">Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="notes"
                  label="Notes"
                  multiline
                  rows={4}
                  value={newAppointment?.notes || ""}
                  onChange={handleAppointmentInputChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNewAppointment}>Cancel</Button>
            <Button onClick={handleSubmitNewAppointment} color="primary" variant="contained">
              Create Appointment
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Appointments;