import React, { useContext, useState, useCallback } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Breadcrumbs,
  Link,
  useMediaQuery,
  useTheme,
  Fade,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../contexts/AuthContext";
import { menuItems } from "./menuItems"; // Move menu items to a separate file

const drawerWidth = 240;

const Layout = React.memo(({ children }) => {
  const { logout } = useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const drawer = (
    <Box
      sx={{
        height: "100%",
        background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        color: "white",
      }}
    >
      <Box sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ fontFamily: "'Playfair Display', serif" }}>
          GLAM Clinic - VioraPro
        </Typography>
        {isMobile && (
  <IconButton 
    color="inherit" 
    onClick={handleDrawerToggle}
    sx={{ color: 'common.white' }} // This ensures the icon is white
  >
    <CloseIcon />
  </IconButton>
)}


      </Box>
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={RouterLink}
            to={item.path}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                transition: "background-color 0.3s",
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        {/* <ListItem button onClick={handleLogout}>
          <ListItemIcon sx={{ color: "inherit" }}>{menuItems[menuItems.length - 1].icon}</ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem> */}
      </List>
    </Box>
  );

  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: "none",
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
            
          >
  <MenuIcon sx={{ color: 'black' }} /> 
  </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, color: "text.primary" }}>
            {pathnames.length > 0 ? pathnames[pathnames.length - 1].charAt(0).toUpperCase() + pathnames[pathnames.length - 1].slice(1) : "Dashboard"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: ["48px", "56px", "64px"],
          transition: "margin 0.3s",
        }}
      >
        <Fade in={true} timeout={800}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
              <Link color="inherit" component={RouterLink} to="/">
                Home
              </Link>
              {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                return last ? (
                  <Typography color="text.primary" key={to}>
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </Typography>
                ) : (
                  <Link color="inherit" component={RouterLink} to={to} key={to}>
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </Link>
                );
              })}
            </Breadcrumbs>
            {children}
          </Box>
        </Fade>
      </Box>
    </Box>
  );
});

export default Layout;