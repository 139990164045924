import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
  Avatar,
} from "@mui/material";
import Layout from "../components/Layout";
import GenericEntityManager from "../components/GenericEntityManager";
import api from "../config/api";

const Products = () => {
  const [currencies, setCurrencies] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const [taxes, setTaxes] = useState([]);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchInitialData();
    fetchCurrencies();
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const [ taxesResponse] = await Promise.all([
        api.get("/api/taxes"),
      ]);
      
      setTaxes(taxesResponse.data.data);
    } catch (error) {
      console.error("Error fetching initial data:", error);
      setError("Failed to load initial data. Please refresh the page.");
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await api.get("/api/currencies");
      setCurrencies(response.data.data);
      const defaultCurr = response.data.data.find(curr => curr.attributes.isDefault);
      setDefaultCurrency(defaultCurr ? defaultCurr.id : null);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching currencies:", error);
      showSnackbar("Error fetching currencies", "error");
      setLoading(false);
    }
  };
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  const fields = useMemo(
    () => [
      {
        name: "image",
        label: "Image",
        type: "image",
        renderCell: (rowData) => (
          <Avatar
            src={rowData.image}
            alt={rowData.name || "Product"}
            sx={{ width: 50, height: 50, cursor: "pointer" }}
            onClick={() => handleImageClick(rowData.image)}
          />
        ),
      },
      {
        name: "name",
        label: "Name",
        type: "text",
        required: true,
        searchable: true,
        filterable: true,
      },
      {
        name: "code",
        label: "Code",
        type: "text",
        required: true,
        searchable: true,
        filterable: true,
      },
      { name: "price", label: "Price", type: "number", required: true },
      { name: "supplierPrice", label: "Supplier Price", type: "number" },
      {
        name: 'currency',
        label: 'Currency',
        type: 'select',
        options: currencies.map((c) => ({
          value: c.id,
          label: `${c.attributes.name} (${c.attributes.symbol})`,
        })),
        required: true,
        renderValue: (value) =>
          currencies.find((c) => c.id === value)?.attributes.symbol || value,
        defaultValue: defaultCurrency,
      },
      {
        name: "description",
        label: "Description",
        type: "text",
        multiline: true,
        rows: 3,
      },
      { name: "fullCode", label: "Full Code", type: "text" },
      { name: "quality", label: "Quality", type: "text" },
      { name: "gColor", label: "G Color", type: "text" },
      { name: "stoneColor", label: "Stone Color", type: "text" },
      { name: "barLength", label: "Bar Length", type: "number" },
      { name: "diameter", label: "Diameter", type: "number" },
      { name: "notes", label: "Notes", type: "text", multiline: true, rows: 2 },
      { name: "category", label: "Category", type: "text", filterable: true },
      {
        name: "applicableTaxes",
        label: "Applicable Taxes",
        type: "multiselect",
        options: taxes.map((t) => ({ value: t.id, label: t.attributes.name })),
        renderValue: (selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={taxes.find((t) => t.id === value)?.attributes.name}
                size="small"
              />
            ))}
          </Box>
        ),
      },
      {
        name: "excludedTaxes",
        label: "Excluded Taxes",
        type: "multiselect",
        options: taxes.map((t) => ({ value: t.id, label: t.attributes.name })),
        renderValue: (selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={taxes.find((t) => t.id === value)?.attributes.name}
                size="small"
              />
            ))}
          </Box>
        ),
      },
    ],
    [currencies,defaultCurrency]
  );

  
  const productService = useMemo(
    () => ({
      getAll: async ({ page, pageSize }) => {
        try {
          const url = `/api/products?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
          const response = await api.get(url);
          const formattedData = response.data.data.map((product) => ({
            id: product.id,
            ...product.attributes,
            currency: product.attributes.currency?.data?.id,
            image: product.attributes.image?.data?.attributes?.url
              ? `${process.env.REACT_APP_API_URL}${product.attributes.image.data.attributes.url}`
              : null,
            applicableTaxes:
              product.attributes.applicableTaxes?.data?.map((tax) => tax.id) ||
              [],
            excludedTaxes:
              product.attributes.excludedTaxes?.data?.map((tax) => tax.id) ||
              [],
          }));
          return {
            data: formattedData,
            meta: response.data.meta,
          };
        } catch (error) {
          console.error("Error fetching products:", error);
          throw new Error("Error fetching products");
        }
      },
      

      
      create: async (productData) => {
        try {
          const formData = new FormData();
          
          const data = {
            name: productData.name,
            code: productData.code,
            price: productData.price,
            supplierPrice: productData.supplierPrice,
            description: productData.description,
            fullCode: productData.fullCode,
            quality: productData.quality,
            gColor: productData.gColor,
            stoneColor: productData.stoneColor,
            barLength: productData.barLength,
            diameter: productData.diameter,
            notes: productData.notes,
            category: productData.category,
            currency: { connect: [productData.currency || defaultCurrency] },

          };

          // Handle relational fields
          // if (productData.currency) {
          //   data.currency = { connect: [productData.currency] };
          // }
          if (productData.applicableTaxes && productData.applicableTaxes.length > 0) {
            data.applicableTaxes = { connect: productData.applicableTaxes };
          }
          if (productData.excludedTaxes && productData.excludedTaxes.length > 0) {
            data.excludedTaxes = { connect: productData.excludedTaxes };
          }

          formData.append('data', JSON.stringify(data));
          
          if (productData.image instanceof File) {
            formData.append('files.image', productData.image, productData.image.name);
          }
  
          const response = await api.post("/api/products", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          showSnackbar("Product created successfully", "success");
          return response.data;
        } catch (error) {
          console.error("Error creating product:", error);
          showSnackbar("Error creating product", "error");
          throw error;
        }
      },
      update: async (id, productData) => {
        try {
          const formData = new FormData();
          
          const data = {
            name: productData.name,
            code: productData.code,
            price: productData.price,
            supplierPrice: productData.supplierPrice,
            description: productData.description,
            fullCode: productData.fullCode,
            quality: productData.quality,
            gColor: productData.gColor,
            stoneColor: productData.stoneColor,
            barLength: productData.barLength,
            diameter: productData.diameter,
            notes: productData.notes,
            category: productData.category,
          };

          // Handle relational fields
          if (productData.currency) {
            data.currency = { connect: [productData.currency] };
          }
          if (productData.applicableTaxes && productData.applicableTaxes.length > 0) {
            data.applicableTaxes = { connect: productData.applicableTaxes };
          }
          if (productData.excludedTaxes && productData.excludedTaxes.length > 0) {
            data.excludedTaxes = { connect: productData.excludedTaxes };
          }

          formData.append('data', JSON.stringify(data));
          
          if (productData.image instanceof File) {
            formData.append('files.image', productData.image, productData.image.name);
          }
  
          const response = await api.put(`/api/products/${id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          showSnackbar("Product updated successfully", "success");
          return response.data;
        } catch (error) {
          console.error("Error updating product:", error);
          showSnackbar("Error updating product", "error");
          throw error;
        }
      },
      delete: async (id) => {
        try {
          const response = await api.delete(`/api/products/${id}`);
          showSnackbar("Product deleted successfully", "success");
          return response.data;
        } catch (error) {
          console.error("Error deleting product:", error);
          showSnackbar("Error deleting product", "error");
          throw error;
        }
      },
    }),
    [defaultCurrency]
  );



  if (loading) {
    return (
      <Layout>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Typography color="error">{error}</Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
       <Box sx={{ width: "100%", mb: 4 }}>
        <GenericEntityManager
          entityName="Product"
          entityNamePlural="Products"
          fields={fields}
          service={productService}
          rowsPerPageOptions={[5, 10, 25, 50, 100]} // Add this line
          onError={(message) => showSnackbar(message, "error")}
        />
      </Box>

      <Dialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Product Image</DialogTitle>
        <DialogContent>
          <img
            src={selectedImage}
            alt="Product"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Products;
