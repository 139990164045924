import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Typography, Snackbar, Alert } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseISO, format, isValid } from 'date-fns';
import GenericEntityManager from '../components/GenericEntityManager';
import Layout from '../components/Layout';
import api from '../config/api';


const Taxes = () => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const showSnackbar = (message, severity = 'info') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const formatDate = (date) => {
    if (!date) return null;
    const parsedDate = typeof date === 'string' ? parseISO(date) : date;
    return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : null;
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const parsedDate = parseISO(dateString);
    return isValid(parsedDate) ? parsedDate : null;
  };

  const taxFields = useMemo(() => [
    { 
      name: 'name', 
      label: 'Name', 
      type: 'text', 
      required: true,
      searchable: true,
      filterable: true
    },
    { 
      name: 'description', 
      label: 'Description', 
      type: 'text', 
      multiline: true,
      rows: 3
    },
    { 
      name: 'rate', 
      label: 'Rate', 
      type: 'number', 
      required: true,
      validate: (value) => value < 0 ? 'Rate cannot be negative' : null
    },
    { 
      name: 'type', 
      label: 'Type', 
      type: 'select', 
      options: [
        { value: 'percentage', label: 'Percentage' },
        { value: 'fixed', label: 'Fixed Amount' }
      ],
      required: true,
      searchable: true,
      filterable: true
    },
    { 
      name: 'isActive', 
      label: 'Active', 
      type: 'boolean',
      filterable: true
    },
    { 
      name: 'applyTo', 
      label: 'Apply To', 
      type: 'select', 
      options: [
        { value: 'subtotal', label: 'Subtotal' },
        { value: 'total', label: 'Total' }
      ],
      required: true,
      searchable: true,
      filterable: true
    },
    { 
      name: 'priority', 
      label: 'Priority', 
      type: 'number', 
      required: true
    },
    { 
      name: 'startDate', 
      label: 'Start Date', 
      type: 'date', 
      parse: parseDate,
      format: formatDate
    },
    { 
      name: 'endDate', 
      label: 'End Date', 
      type: 'date', 
      parse: parseDate,
      format: formatDate
    }
  ], []);

  const taxService = useMemo(() => ({
    getAll: async (params = {}) => {
      try {
        let url = '/api/taxes?populate=*';
        if (params.page && params.pageSize) {
          url += `&pagination[page]=${params.page}&pagination[pageSize]=${params.pageSize}`;
        } else {
          url += '&pagination[pageSize]=-1'; // Fetch all customers for export
        }
        const response = await api.get(url);
        const formattedData = response.data.data.map((tax) => ({
          id: tax.id,
          ...tax.attributes,
          startDate: parseDate(tax.attributes.startDate),
          endDate: parseDate(tax.attributes.endDate)
        }));
        return {
          data: formattedData,
          meta: response.data.meta,
        };
      } catch (error) {
        console.error('Error fetching Taxes:', error);
        throw new Error('Error fetching Taxes');
      }
    },
    create: async (taxData) => {
      try {
        const response = await api.post('/api/taxes', { 
          data: {
            ...taxData,
            startDate: formatDate(taxData.startDate),
            endDate: formatDate(taxData.endDate)
          }
        });
        showSnackbar('Tax created successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error creating tax:', error.response?.data || error.message);
        showSnackbar(`Error creating tax: ${error.response?.data?.error?.message || error.message}`, 'error');
        throw error;
      }
    },
    update: async (id, taxData) => {
      try {
        const response = await api.put(`/api/taxes/${id}`, { 
          data: {
            ...taxData,
            startDate: formatDate(taxData.startDate),
            endDate: formatDate(taxData.endDate)
          }
        });
        showSnackbar('Tax updated successfully', 'success');
        return response.data.data;
      } catch (error) {
        console.error('Error updating tax:', error.response?.data || error.message);
        showSnackbar(`Error updating tax: ${error.response?.data?.error?.message || error.message}`, 'error');
        throw error;
      }
    },
    delete: async (id) => {
      try {
        await api.delete(`/api/taxes/${id}`);
        showSnackbar('Tax deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting tax:', error);
        showSnackbar(`Error deleting tax: ${error.message}`, 'error');
        throw error;
      }
    },
  }), []);

  const handleApplicableProducts = async (tax) => {
    try {
      const products = await api.get(`/api/taxes/${tax.id}?populate=applicableProducts`);
      // Handle the logic to show and update applicable products
      // This could open a new dialog or navigate to a new page
      console.log('Applicable products:', products.data.data.attributes.applicableProducts.data);
    } catch (error) {
      console.error('Error fetching applicable products:', error);
      showSnackbar('Error fetching applicable products', 'error');
    }
  };

  const handleApplicableServices = async (tax) => {
    try {
      const services = await api.get(`/api/taxes/${tax.id}?populate=applicableServices`);
      // Handle the logic to show and update applicable services
      // This could open a new dialog or navigate to a new page
      console.log('Applicable services:', services.data.data.attributes.applicableServices.data);
    } catch (error) {
      console.error('Error fetching applicable services:', error);
      showSnackbar('Error fetching applicable services', 'error');
    }
  };

  const customActions = [
    {
      name: 'Applicable Products',
      handler: handleApplicableProducts
    },
    {
      name: 'Applicable Services',
      handler: handleApplicableServices
    }
  ];

  return (
    <Layout>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ width: '100%', mb: 4 }}>
          <GenericEntityManager
            entityName="Tax"
            entityNamePlural="Taxes"
            fields={taxFields}
            service={taxService}
            customActions={customActions}
          />
        </Box>
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </LocalizationProvider>
    </Layout>
  );
};
export default Taxes;