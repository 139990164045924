import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  Snackbar,
  Alert,
  Avatar,
  CircularProgress
} from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import Layout from './Layout';
import api from '../config/api';

const Settings = () => {
  const [businessInfo, setBusinessInfo] = useState({
    name: '',
    legalName: '',
    address: '',
    phone: '',
    email: '',
    website: '',
    taxId: '',
    registrationNumber: '',
    logo: null
  });
  const [logoPreview, setLogoPreview] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    fetchBusinessInfo();
  }, []);

  const fetchBusinessInfo = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/business');
      if (response.data.data) {
        setBusinessInfo(response.data.data.attributes);
        if (response.data.data.attributes.logo) {
          setLogoPreview(`${process.env.REACT_APP_API_URL}${response.data.data.attributes.logo.url}`);
        }
      }
    } catch (error) {
      console.error('Error fetching business info:', error);
      showSnackbar('Error fetching business information', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessInfo(prev => ({ ...prev, [name]: value }));
    setIsDirty(true);
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBusinessInfo(prev => ({ ...prev, logo: file }));
      setLogoPreview(URL.createObjectURL(file));
      setIsDirty(true);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!businessInfo.name) newErrors.name = 'Business name is required';
    if (!businessInfo.legalName) newErrors.legalName = 'Legal name is required';
    if (businessInfo.email && !/\S+@\S+\.\S+/.test(businessInfo.email)) newErrors.email = 'Invalid email format';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setSaving(true);
    try {
      const formData = new FormData();
      
      // Prepare the data object
      const dataObject = Object.keys(businessInfo).reduce((acc, key) => {
        // Only include non-empty values and exclude the logo
        if (key !== 'logo' && businessInfo[key] !== '' && businessInfo[key] !== null && businessInfo[key] !== undefined) {
          acc[key] = businessInfo[key];
        }
        return acc;
      }, {});
      
      // Stringify the cleaned data object
      formData.append('data', JSON.stringify(dataObject));
  
      if (businessInfo.logo instanceof File) {
        formData.append('files.logo', businessInfo.logo, businessInfo.logo.name);
      }
  
      const response = await api.put('/api/business', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('Business info updated:', response.data);
      showSnackbar('Business information updated successfully', 'success');
      setIsDirty(false);
    } catch (error) {
      console.error('Error updating business info:', error.response?.data || error.message);
      showSnackbar(`Error updating business information: ${error.response?.data?.error?.message || error.message}`, 'error');
    } finally {
      setSaving(false);
    }
  };


  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Layout>
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Typography variant="h4" component="h1" gutterBottom>
        Business Settings
      </Typography>
      <Paper elevation={3}>
        <Box p={3}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="logo-upload"
                    type="file"
                    onChange={handleLogoChange}
                  />
                  <label htmlFor="logo-upload">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Logo
                    </Button>
                  </label>
                  {logoPreview && (
                    <Avatar
                      src={logoPreview}
                      alt="Business Logo"
                      sx={{ width: 100, height: 100, mt: 2 }}
                    />
                  )}
                </Box>

                <TextField
                  name="name"
                  label="Business Name"
                  fullWidth
                  value={businessInfo.name}
                  onChange={handleInputChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="legalName"
                  label="Legal Name"
                  fullWidth
                  value={businessInfo.legalName}
                  onChange={handleInputChange}
                  error={!!errors.legalName}
                  helperText={errors.legalName}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="address"
                  label="Address"
                  fullWidth
                  multiline
                  rows={3}
                  value={businessInfo.address}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phone"
                  label="Phone"
                  fullWidth
                  value={businessInfo.phone}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  fullWidth
                  value={businessInfo.email}
                  onChange={handleInputChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="website"
                  label="Website"
                  fullWidth
                  value={businessInfo.website}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="taxId"
                  label="Tax ID"
                  fullWidth
                  value={businessInfo.taxId}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="registrationNumber"
                  label="Registration Number"
                  fullWidth
                  value={businessInfo.registrationNumber}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary" 
                  disabled={saving || !isDirty}
                >
                  {saving ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Settings;