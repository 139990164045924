import { useState, useEffect,useCallback } from "react";
import { OrdersService } from "./OrdersService";
import api from "../../config/api";
export const useOrdersController = () => {
  const [orders, setOrders] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [productsPagination, setProductsPagination] = useState({ page: 1, pageSize: 25, total: 0 });
  const [servicesPagination, setServicesPagination] = useState({ page: 1, pageSize: 25, total: 0 });
  const [productSearch, setProductSearch] = useState('');
  const [serviceSearch, setServiceSearch] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [newOrder, setNewOrder] = useState(null);
  const [editingOrder, setEditingOrder] = useState(null);
  const [taxes, setTaxes] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0,
  });

  const [filters, setFilters] = useState({
    orderNumber: "",
    customer: "",
    status: "",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [sortField, setSortField] = useState("orderDate");
  const [sortOrder, setSortOrder] = useState("desc");
  useEffect(() => {
    fetchOrders();
    fetchCustomers();
    fetchProducts();
    fetchServices();
    fetchPaymentMethods();
    fetchCurrencies();
    fetchTaxes();
  }, [pagination.page, pagination.pageSize]);

  const fetchTaxes = async () => {
    try {
      const data = await OrdersService.getTaxes();
      setTaxes(data);
    } catch (error) {
      console.error("Error fetching taxes:", error);
      showSnackbar("Error fetching taxes", "error");
    }
  };

  
  const fetchOrders = async () => {
    try {
      const response = await OrdersService.getOrders({
        page: pagination.page + 1,
        pageSize: pagination.pageSize,
        ...filters,
        sortField,
        sortOrder,
      });
      setOrders(response.data);
      setPagination(prev => ({
        ...prev,
        total: response.meta.pagination.total,
      }));
    } catch (error) {
      console.error("Error fetching orders:", error);
      showSnackbar("Error fetching orders", "error");
    }
  };

  const handleSort = (field) => {
    setSortOrder(sortField === field && sortOrder === "asc" ? "desc" : "asc");
    setSortField(field);
    fetchOrders();
  };


  const handlePageChange = (event, newPage) => {
    setPagination(prev => ({ ...prev, page: newPage }));
    fetchOrders();
  };

  const handleRowsPerPageChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPagination(prev => ({
      ...prev,
      pageSize: newPageSize,
      page: 0,
    }));
    fetchOrders();
  };



  const handleFilterChange = (event) => {
    setFilters(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const applyFilters = () => {
    setPagination(prev => ({ ...prev, page: 0 }));
    fetchOrders();
  };

  const fetchCustomers = async () => {
    try {
      const data = await OrdersService.getCustomers();
      setCustomers(data);
    } catch (error) {
      console.error("Error fetching customers:", error);
      showSnackbar("Error fetching customers", "error");
    }
  };

  const fetchProducts = async (page = 1, search = '') => {
    try {
      const data = await OrdersService.getProducts(page, productsPagination.pageSize, search);
      setProducts(prevProducts => page === 1 ? data.data : [...prevProducts, ...data.data]);
      setProductsPagination({
        page: data.meta.pagination.page,
        pageSize: data.meta.pagination.pageSize,
        total: data.meta.pagination.total,
      });
    } catch (error) {
      console.error("Error fetching products:", error);
      showSnackbar("Error fetching products", "error");
    }
  };

  const fetchServices = async (page = 1, search = '') => {
    try {
      const data = await OrdersService.getServices(page, servicesPagination.pageSize, search);
      setServices(prevServices => page === 1 ? data.data : [...prevServices, ...data.data]);
      setServicesPagination({
        page: data.meta.pagination.page,
        pageSize: data.meta.pagination.pageSize,
        total: data.meta.pagination.total,
      });
    } catch (error) {
      console.error("Error fetching services:", error);
      showSnackbar("Error fetching services", "error");
    }
  };

  const handleProductSearch = (search) => {
    setProductSearch(search);
    fetchProducts(1, search);
  };

  const handleServiceSearch = (search) => {
    setServiceSearch(search);
    fetchServices(1, search);
  };

  const loadMoreProducts = () => {
    if (products.length < productsPagination.total) {
      fetchProducts(productsPagination.page + 1, productSearch);
    }
  };

  const loadMoreServices = () => {
    if (services.length < servicesPagination.total) {
      fetchServices(servicesPagination.page + 1, serviceSearch);
    }
  };


  const searchCustomer = async (phoneNumber) => {
    try {
      const encodedPhoneNumber = encodeURIComponent(phoneNumber);
      const response = await api.get(`/api/customers?filters[phone][$eq]=${encodedPhoneNumber}`);
      if (response.data.data && response.data.data.length > 0) {
        const customer = response.data.data[0];
        return {
          id: customer.id,
          ...customer.attributes
        };
      }
      return null;
    } catch (error) {
      console.error("Error searching for customer:", error);
      showSnackbar("Error searching for customer", "error");
      return null;
    }
  };

  const createCustomer = async (customerData) => {
    try {
      const cleanedData = Object.entries(customerData).reduce((acc, [key, value]) => {
        if (value !== null && value !== "") {
          acc[key] = value;
        }
        return acc;
      }, {});

      const response = await api.post("/api/customers", { data: cleanedData });
      showSnackbar("Customer created successfully", "success");
      return {
        id: response.data.data.id,
        ...response.data.data.attributes
      };
    } catch (error) {
      console.error("Error creating customer:", error);
      showSnackbar("Error creating customer", "error");
      throw error;
    }
  };


 

  const fetchPaymentMethods = async () => {
    try {
      const data = await OrdersService.getPaymentMethods();
      setPaymentMethods(data);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      showSnackbar("Error fetching payment methods", "error");
    }
  };

  const fetchCurrencies = async () => {
    try {
      const data = await OrdersService.getCurrencies();
      setCurrencies(data);
    } catch (error) {
      console.error("Error fetching currencies:", error);
      showSnackbar("Error fetching currencies", "error");
    }
  };

  const handleOpen = useCallback((customerId, customerName) => {
    setNewOrder({
      customer: customerId,
      customerName: customerName,
      orderItems: [],
      subtotal: 0,
      tax: 0,
      totalAmount: 0,
      status: "pending",
      paymentMethod: "",
      currency: "",
      orderDate: new Date().toISOString().split("T")[0],
    });
  }, []);


  const handleClose = () => setNewOrder(null);

  const handleInputChange = (e) => {
    setNewOrder({ ...newOrder, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await OrdersService.createOrder(newOrder);
      fetchOrders();
      handleClose();
      showSnackbar("Order created successfully", "success");
    } catch (error) {
      console.error("Error creating order:", error);
      showSnackbar(`Error creating order: ${error.message}`, "error");
    }
  };

  const updateOrderItem = (index, field, value) => {
    const updatedItems = [...newOrder.orderItems];
    if (field === "productOrService") {
      const [type, idString] = value.split("-");
      const id = parseInt(idString, 10);
      let unitPrice = 0;
      if (type === "product") {
        const selectedProduct = products.find((p) => p.id === id);
        unitPrice = selectedProduct ? parseFloat(selectedProduct.attributes.price) : 0;
      } else if (type === "service") {
        const selectedService = services.find((s) => s.id === id);
        unitPrice = selectedService ? parseFloat(selectedService.attributes.price) : 0;
      }
      updatedItems[index] = { ...updatedItems[index], type, id, unitPrice };
    } else {
      updatedItems[index] = { ...updatedItems[index], [field]: value };
    }
    setNewOrder((prevOrder) => ({
      ...prevOrder,
      orderItems: updatedItems,
    }));
    calculateTotals(updatedItems);
  };

  const addOrderItem = () => {
    setNewOrder((prevOrder) => ({
      ...prevOrder,
      orderItems: [
        ...prevOrder.orderItems,
        { type: "", id: "", quantity: 1, unitPrice: 0 },
      ],
    }));
  };

  const removeOrderItem = (index) => {
    const updatedItems = newOrder.orderItems.filter((_, i) => i !== index);
    setNewOrder((prevOrder) => ({
      ...prevOrder,
      orderItems: updatedItems,
    }));
    calculateTotals(updatedItems);
  };

  const calculateTotals = (items) => {
    const subtotal = items.reduce(
      (sum, item) => sum + item.quantity * item.unitPrice,
      0
    );
    const tax = OrdersService.calculateTax(subtotal, taxes);
    const totalAmount = subtotal + tax;

    setNewOrder((prevOrder) => ({
      ...prevOrder,
      subtotal: parseFloat(subtotal.toFixed(2)),
      tax: parseFloat(tax.toFixed(2)),
      totalAmount: parseFloat(totalAmount.toFixed(2)),
    }));
  };


  const handleEditOpen = (order) => {
    
    const formattedOrderItems = order.attributes.orderItems?.data?.map(item => {
      
      const productData = item.attributes.product?.data;
      const serviceData = item.attributes.service?.data;
      
      const isProduct = !!productData;
      const itemData = isProduct ? productData : serviceData;
      
      if (!itemData) {
        console.warn("Item data is missing for order item:", item.id);
        return null;
      }
      
      return {
        id: item.id,
        type: isProduct ? 'product' : 'service',
        itemId: itemData.id,
        name: itemData.attributes?.name || 'Unknown Name',
        quantity: item.attributes.quantity || 0,
        unitPrice: item.attributes.unitPrice || 0
      };
    }).filter(Boolean) || [];
  
  
    setEditingOrder({
      id: order.id,
      customer: order.attributes.customer?.data?.id,
      paymentMethod: order.attributes.paymentMethod?.data?.id,
      currency: order.attributes.currency?.data?.id,
      attributes: {
        ...order.attributes,
        orderDate: order.attributes.orderDate?.split('T')[0] || '',
      },
      orderItems: formattedOrderItems
    });
  };

  const handleEditClose = () => setEditingOrder(null);

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingOrder((prev) => ({
      ...prev,
      attributes: {
        ...prev.attributes,
        [name]: value,
      },
    }));
  };

  const updateEditOrderItem = (index, field, value) => {
    const updatedItems = [...editingOrder.orderItems];
    if (field === "productOrService") {
      const [type, itemId] = value.split("-");
      const item = type === "product"
        ? products.find(p => p.id === parseInt(itemId, 10))
        : services.find(s => s.id === parseInt(itemId, 10));
  
      if (item) {
        updatedItems[index] = {
          ...updatedItems[index],
          type,
          itemId: parseInt(itemId, 10),
          name: item.attributes.name,
          unitPrice: parseFloat(item.attributes.price)
        };
      }
    } else {
      updatedItems[index][field] = value;
    }
  
    setEditingOrder(prev => ({
      ...prev,
      orderItems: updatedItems,
    }));
  
    calculateEditTotals(updatedItems);
  };

  const addEditOrderItem = () => {
    setEditingOrder((prev) => ({
      ...prev,
      orderItems: [
        ...prev.orderItems,
        { type: "", itemId: "", quantity: 1, unitPrice: 0 },
      ],
    }));
  };

  const removeEditOrderItem = (index) => {
    const updatedItems = editingOrder.orderItems.filter((_, i) => i !== index);
    setEditingOrder((prev) => ({
      ...prev,
      orderItems: updatedItems,
    }));
    calculateEditTotals(updatedItems);
  };

  const calculateEditTotals = (items) => {
    const subtotal = items.reduce(
      (sum, item) => sum + item.quantity * item.unitPrice,
      0
    );
    const tax = OrdersService.calculateTax(subtotal, taxes);
    const totalAmount = subtotal + tax;

    setEditingOrder((prev) => ({
      ...prev,
      attributes: {
        ...prev.attributes,
        subtotal: parseFloat(subtotal.toFixed(2)),
        tax: parseFloat(tax.toFixed(2)),
        totalAmount: parseFloat(totalAmount.toFixed(2)),
      },
    }));
  };


  const handleEditSubmit = async () => {
    try {
      await OrdersService.updateOrder(editingOrder);
      fetchOrders();
      handleEditClose();
      showSnackbar("Order updated successfully", "success");
    } catch (error) {
      console.error("Error updating order:", error);
      showSnackbar(`Error updating order: ${error.message}`, "error");
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return {
    orders,
    customers,
    products,
    services,
    paymentMethods,
    currencies,
    newOrder,
    taxes,
    editingOrder,
    snackbar,
    pagination,
    filters,
    productsPagination,
    servicesPagination,
    handleSort,
    sortField,
    sortOrder,
    handlePageChange,
    handleRowsPerPageChange,
    handleFilterChange,
    applyFilters,
    handleOpen,
    handleClose,
    handleInputChange,
    handleSubmit,
    updateOrderItem,
    addOrderItem,
    removeOrderItem,
    handleEditOpen,
    handleEditClose,
    handleEditInputChange,
    updateEditOrderItem,
    addEditOrderItem,
    removeEditOrderItem,
    searchCustomer,
    createCustomer,
    handleProductSearch,
    handleServiceSearch,
    loadMoreProducts,
    loadMoreServices,
    handleEditSubmit,
    handleSnackbarClose,
  };
};