import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  useTheme,
  alpha,Zoom
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import RefreshIcon from '@mui/icons-material/Refresh';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import Layout from './Layout';
import api from '../config/api';
import { useSpring, animated } from 'react-spring';
import { motion } from 'framer-motion';

const Dashboard = () => {
  const theme = useTheme();
  const [stats, setStats] = useState({
    totalOrders: 0,
    totalRevenue: 0,
    totalCustomers: 0,
    totalAppointments: 0
  });
  const [recentOrders, setRecentOrders] = useState([]);
  const [recentAppointments, setRecentAppointments] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [defaultCurrency, setDefaultCurrency] = useState({ symbol: '$', code: 'USD' });
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
  
  useEffect(() => {
    fetchDashboardData();
    fetchDefaultCurrency();
  }, []);

  const fetchDefaultCurrency = async () => {
    try {
      const response = await api.get('/api/currencies?filters[isDefault][$eq]=true');
      if (response.data.data.length > 0) {
        const currency = response.data.data[0].attributes;
        setDefaultCurrency({ symbol: currency.symbol, code: currency.code });
      }
    } catch (error) {
      console.error('Error fetching default currency:', error);
    }
  };

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const [ordersResponse, appointmentsResponse, productsResponse] = await Promise.all([
        api.get('/api/orders?sort[0]=createdAt:desc&pagination[limit]=-1&populate=*'),
        api.get('/api/appointments?sort[0]=dateTime:desc&pagination[limit]=5&populate=*'),
        api.get('/api/products?pagination[limit]=-1')
      ]);
  
      setRecentOrders(ordersResponse.data.data.slice(0, 5));
      setRecentAppointments(appointmentsResponse.data.data);
  
      const orders = ordersResponse.data.data;
      const products = productsResponse.data.data;
  
      // Calculate stats from orders
      const calculatedStats = {
        totalOrders: orders.length,
        totalRevenue: orders.reduce((sum, order) => sum + parseFloat(order.attributes.totalAmount), 0),
        totalCustomers: new Set(orders.map(order => order.attributes.customer.data.id)).size,
        totalAppointments: appointmentsResponse.data.meta.pagination.total
      };
      setStats(calculatedStats);
  
      // Calculate sales data
      const salesByDate = orders.reduce((acc, order) => {
        const date = new Date(order.attributes.createdAt).toLocaleDateString();
        acc[date] = (acc[date] || 0) + parseFloat(order.attributes.totalAmount);
        return acc;
      }, {});
      const formattedSalesData = Object.entries(salesByDate).map(([date, sales]) => ({ date, sales }));
      setSalesData(formattedSalesData);
  
      // Calculate top products
      const productSales = {};
      orders.forEach(order => {
        order.attributes.orderItems.data.forEach(item => {
          const productId = item.attributes.product.data.id;
          productSales[productId] = (productSales[productId] || 0) + item.attributes.quantity;
        });
      });
  
      const topProducts = products
        .map(product => ({
          ...product,
          salesCount: productSales[product.id] || 0
        }))
        .sort((a, b) => b.salesCount - a.salesCount)
        .slice(0, 5);
  
      setTopProducts(topProducts);
  
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  const StatCard = ({ title, value, previousValue, icon: Icon, isCurrency }) => {
    const percentChange = previousValue ? ((value - previousValue) / previousValue) * 100 : 0;
    return (
      <Zoom in={true} style={{ transitionDelay: '250ms' }}>
        <Card elevation={3} sx={{ height: '100%', backgroundColor: alpha(theme.palette.primary.main, 0.05) }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6" color="text.secondary">{title}</Typography>
              <Icon color="primary" fontSize="large" />
            </Box>
            <Typography variant="h4" fontWeight="bold">
              {isCurrency ? `${defaultCurrency.symbol}${value.toFixed(2)}` : value.toLocaleString()}
            </Typography>
            {previousValue && (
              <Box display="flex" alignItems="center" mt={1}>
                {percentChange > 0 ? <TrendingUpIcon color="success" /> : <TrendingDownIcon color="error" />}
                <Typography variant="body2" color={percentChange > 0 ? "success.main" : "error.main"} ml={0.5}>
                  {Math.abs(percentChange).toFixed(2)}%
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Zoom>
    );
  };




  
const SkeletonLoader = () => (
  <Box sx={{ width: '100%', height: '200px', backgroundColor: '#f0f0f0', borderRadius: '8px' }} />
);
if (loading) {
  return (
    <Layout>
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <SkeletonLoader />
      </Box>
    </Layout>
  );
}

  return (
    <Layout>
      <animated.div style={fadeIn}>

      <Box mb={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h4" component="h1" fontWeight="bold" color="primary">
            Dashboard
          </Typography>
          <Tooltip title="Refresh Data">
            <IconButton onClick={fetchDashboardData} size="large">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard 
              title="Total Orders" 
              value={stats.totalOrders} 
              previousValue={stats.totalOrders * 0.9} 
              icon={ShoppingCartIcon}
              isCurrency={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard 
              title="Total Revenue" 
              value={stats.totalRevenue} 
              previousValue={stats.totalRevenue * 0.85} 
              icon={MonetizationOnIcon}
              isCurrency={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard 
              title="Total Customers" 
              value={stats.totalCustomers} 
              previousValue={stats.totalCustomers * 0.95} 
              icon={PeopleIcon}
              isCurrency={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard 
              title="Total Appointments" 
              value={stats.totalAppointments} 
              previousValue={stats.totalAppointments * 0.88} 
              icon={EventIcon}
              isCurrency={false}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom fontWeight="bold">Sales Overview</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={salesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                <Line type="monotone" dataKey="sales" stroke={theme.palette.primary.main} activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom fontWeight="bold">Recent Orders</Typography>
            <List>
              {recentOrders.map((order, index) => (
                <React.Fragment key={order.id}>
                  <ListItem>
                    <ListItemText
                      primary={`Order #${order.attributes.orderNumber}`}
                      secondary={`${defaultCurrency.symbol}${order.attributes.totalAmount} - ${new Date(order.attributes.createdAt).toLocaleDateString()}`}
                    />
                  </ListItem>
                  {index < recentOrders.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom fontWeight="bold">Upcoming Appointments</Typography>
            <List>
              {recentAppointments.map((appointment, index) => (
                <React.Fragment key={appointment.id}>
                  <ListItem>
                    <ListItemText
                      primary={`${appointment.attributes.customer.data.attributes.firstName} ${appointment.attributes.customer.data.attributes.lastName}`}
                      secondary={`${new Date(appointment.attributes.dateTime).toLocaleString()} - ${appointment.attributes.status}`}
                    />
                  </ListItem>
                  {index < recentAppointments.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom fontWeight="bold">Top Products</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={topProducts}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="attributes.name" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="salesCount" fill={theme.palette.secondary.main} />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
      </animated.div>

    </Layout>
  );
};

export default Dashboard;
